<template>
  <el-form size="mini" :model="form">
    <el-form-item v-for="(val, key) in form" :key="key" :label="key">
      <DeepForm v-if="typeStr(form[key]) === '[object Object]'" v-model="form[key]" />
      <el-input-number v-else-if="typeStr(form[key]) === 'number'" v-model="form[key]" controls-position="right" />
      <el-input v-else-if="typeStr(form[key]) === 'string'" v-model="form[key]" style="width: 120px;" />
      <el-switch v-else-if="typeStr(form[key]) === 'boolean'" v-model="form[key]" />
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: 'DeepForm',
  props: {
    value: {
      type: Object,
      require: true
    }
  },
  data() {
    return {
      beforeMergeForm: {}
    }
  },
  computed: {
    form: {
      get() {
        return this.value
      },
      set(val) {
        this.beforeMergeForm = val
      }
    },
    typeStr() {
      return row => {
        if (typeof row === 'object') {
          return Object.prototype.toString.call(row)
        }
        return typeof row
      }
    }
  },
  mounted() {},
  methods: {}
}
</script>
