<template>
  <el-popover
    v-model="visible"
    v-bind="$attrs"
    placement="bottom-start"
    trigger="click"
    @after-leave="afterLeave"
    v-on="$listeners"
  >
    <el-select
      v-model="form.tableName"
      style="width: 100%; margin-bottom: 10px;"
      placeholder="请选择表"
      @change="getReportDetailList"
    >
      <el-option
        v-for="item in tableList"
        :key="item.tableColUnitId"
        :label="item.tableDesc"
        :value="item.tableName"
      />
    </el-select>
    <el-input v-model="filterValue" placeholder="请输入要筛选的内容" />
    <el-cascader-panel
      v-model="moduleValue"
      style="margin: 10px 0;"
      class="cascader-panel"
      :options="dealAggregationList"
    />
    <div :style="`display: grid; grid-template-columns: repeat(${moduleValue.length}, 1fr);`">
      <el-button plain @click="visible = false"> 关闭 </el-button>
      <el-button v-if="moduleValue.length" type="primary" @click="submit"> 确定 </el-button>
    </div>
    <template slot="reference">
      <slot />
    </template>
  </el-popover>
</template>

<script>
export default {
  name: 'SelectInfos',
  props: {
    tableList: {
      type: Array,
      default: () => []
    },
    aggregationList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      // 聚合条件
      visible: false,
      filterValue: '',
      moduleValue: [],
      form: {
        tableName: ''
      },
      reportDetailList: []
    }
  },
  computed: {
    filterModuleValue() {
      return this.reportDetailList.filter(item => item.colDesc.includes(this.filterValue))
    },
    dealAggregationList() {
      return this.aggregationList?.map(aggregationItem => {
        const { sqlText, sqlMemberId } = aggregationItem
        aggregationItem.children = []
        this.filterModuleValue.map(item => {
          const obj = {
            ...item,
            label: item.colDesc,
            value: item.tableColUnitId,
            sqlMemberId
          }
          if (sqlText.indexOf('count') !== -1) {
            // 总行数
            aggregationItem.children.push(obj)
          } else if (sqlText.indexOf('distinct') !== -1) {
            // 去重总行数
            aggregationItem.children.push(obj)
          } else if (sqlText.indexOf('sum') !== -1) {
            // 求和
            if (item.dataType === 1) {
              aggregationItem.children.push(obj)
            }
          } else if (sqlText.indexOf('avg') !== -1) {
            // 平均数
            if (item.dataType === 1) {
              aggregationItem.children.push(obj)
            }
          }
        })
        return { ...aggregationItem, label: aggregationItem.showText, value: aggregationItem.sqlMemberId }
      })
    }
  },
  methods: {
    // 所有指标
    async getReportDetailList(tableName) {
      try {
        this.loading = true
        const res = await this.$axios.get(this.$API.getColumnList, { params: { tableName } })
        this.loading = false
        this.reportDetailList = res
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    },
    afterLeave() {
      this.moduleValue = []
    },
    submit() {
      const [sqlMemberIdStr, tableColUnitId] = this.moduleValue
      const [{ children, showText }] = this.dealAggregationList.filter(item => item.sqlMemberId === sqlMemberIdStr)
      const [{colDesc, colName, sqlMemberId}] = children?.filter(item => item.value === tableColUnitId)
      const selectInfos = [
        {
          colName,
          sqlMemberId,
          aliasName: `${colDesc}『${showText}』`
        }
      ]
      // eslint-disable-next-line vue/custom-event-name-casing
      this.$emit('beforeClose', {
        ...this.form,
        ...{
          selectInfos: [],
          orderByInfos: [],
          groupByInfos: [],
          whereInfos: []
        },
        selectInfos
      }, this.onCloseSelectInfos)
      this.visible = false
    },
    onCloseSelectInfos() {
      this.visible = false
      this.moduleValue = []
    }
  }
}
</script>

<style lang="scss" scoped>
.cascader-panel {
  :deep(.el-cascader-menu__wrap) {
    width: 266px;
    height: 300px;
  }
}
</style>
