
<template>
  <!-- 聚合 -->
  <div class="aggregation">
    <el-input v-model="filterName" placeholder="输入排序条件" />
    <div v-for="tag in filterReportDetailList" :key="keyStr(tag)" class="group-list">
      <svg-icon :name="groupItemIcon(tag)" class="group-icon" />
      <el-tag
        class="check-list-item"
        :hit="false"
        :effect="tagAct(tag) ? 'dark' : 'plain'"
        :type="tagAct(tag) ? 'success' : 'info'"
        :closable="tagAct(tag)"
        @close="onRemoveorderByInfos(tag)"
        @click="onAddorderByInfos(tag)"
      >
        <div class="text" :title="tag.colDesc">{{ tag.colDesc }}</div>
        <el-select
          v-if="tagAct(tag)"
          :value="orderByItemValue(tag)"
          class="tag-range"
          placeholder="请选择"
          @change="e => onChangeGroupByItem(e, tag)"
        >
          <el-option
            v-for="orderByItem in rankRange"
            :key="orderByItem.sqlMemberId"
            :label="orderByItem.showText"
            :value="orderByItem.sqlText"
          />
        </el-select>
      </el-tag>
    </div>
  </div>
</template>

<script>
import { getRequestSqlMemberList } from '@/apis/reportService.js'
export default {
  name: 'RankCom',
  props: {
    value: {
      type: Object,
      default: Object
    },
    reportDetailList: {
      type: Array,
      default: Array
    },
    groupItemIcon: Function
  },
  data() {
    return {
      filterName: '',
      // 分组条件中日期范围
      rankRange: []
    }
  },
  computed: {
    actForm: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    tagAct() {
      return tag => this.actForm.orderByInfos?.some(item => this.keyStr(item) === this.keyStr(tag))
    },
    orderByItemValue() {
      return tag => {
        const [{ orderByType }] = this.actForm?.orderByInfos?.filter(item => {
          return this.keyStr(item) === this.keyStr(tag)
        })
        if (orderByType) {
          return orderByType
        } else {
          return ''
        }
      }
    },
    filterReportDetailList() {
      const selectInfosList = this.actForm?.selectInfos?.map(item => {
        return {
          colName: item.colName,
          colDesc: item.aliasName,
          aggregateSqlMemberId: item.sqlMemberId,
          dataType: 'aggregation'
        }
      }) || []
      const calculateColInfosList = this.actForm.calculateColInfos?.map(item => {
        return {
          colDesc: item.aliasName,
          calculateColInfo: item,
          dataType: 'aggregation'
        }
      }) || []
      return [...selectInfosList, ...calculateColInfosList, ...this.reportDetailList].filter(item => {
        return item.colDesc.includes(this.filterName)
      })
    },
    keyStr() {
      return row => {
        if (row.calculateColInfo) {
          return JSON.stringify(row.calculateColInfo)
        } else {
          return `${row.colName}${row.aggregateSqlMemberId || row.tableColUnitId || ''}`
        }
      }
    }
  },
  mounted() {
    this.getAggregation()
  },
  methods: {
    // 聚合条件
    async getAggregation() {
      try {
        const res = await getRequestSqlMemberList({ type: 3 })
        this.rankRange = res
      } catch (error) {
        console.log(error)
      }
    },
    onAddorderByInfos(tag) {
      const [row] = this.actForm.orderByInfos?.filter(item => this.keyStr(item) === this.keyStr(tag)) || []
      if (row) return
      const { colName, aggregateSqlMemberId, calculateColInfo } = tag
      const [{ sqlText = '', sqlMemberId }] = this.rankRange
      const obj = { colName: colName, orderByType: sqlText }
      aggregateSqlMemberId && (obj.aggregateSqlMemberId = aggregateSqlMemberId)
      sqlMemberId && (obj.sqlMemberId = sqlMemberId)
      calculateColInfo && (obj.calculateColInfo = calculateColInfo)
      if (!this.actForm.orderByInfos) {
        this.actForm.orderByInfos = []
      }
      this.actForm.orderByInfos.push(obj)
    },
    onRemoveorderByInfos(tag) {
      this.actForm.orderByInfos = this.actForm.orderByInfos?.filter(item => {
        return this.keyStr(item) !== this.keyStr(tag)
      })
      if (this.actForm.orderByInfos.length === 1) {
        this.actForm.orderByInfos[0].showAxis = 1
      }
    },
    onChangeGroupByItem(sqlText, tag) {
      this.actForm.orderByInfos = this.actForm.orderByInfos?.map(item => {
        if (this.keyStr(item) === this.keyStr(tag)) {
          item.orderByType = sqlText
        } else {
          item.orderByType = item.orderByType || ''
        }
        this.rankRange.forEach(rankRangeItem => {
          if (rankRangeItem.sqlText === item.orderByType) {
            item.sqlMemberId = rankRangeItem.sqlMemberId
          }
        })
        return item
      })
    },
    onAddSelectInfos(res, close) {
      const flag = this.actForm.selectInfos.some(item => {
        if (res.pathId === item.pathId) {
          return true
        }
      })
      if (!flag) {
        this.actForm.selectInfos.push(res)
        close()
      } else {
        this.$message.error('聚合条件重复')
      }
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.aggregation {
  .group-list {
    display: flex;
    align-items: center;
    .group-icon {
      margin-right: 5px;
      flex-shrink: 1;
    }
    .check-list-item {
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 4px;
      padding-right: 10px;
      border-radius: 2px;
      transition: all 0.2s;
      margin: 2px 0;
      font-size: 14px;
      line-height: 35px;
      border: 1px solid transparent;
      cursor: pointer;
      span {
        cursor: pointer;
        flex-shrink: 1;
        overflow-x: auto;
      }
      .text {
        width: 120px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .tag-range {
        color: #fff;
        width: 100px;
        :deep(.el-input) {
          height: 30px;
          font-size: 0;
          box-sizing: border-box;
          .el-input__inner {
            width: 100px;
            font-size: 14px;
            color: #fff;
            &::placeholder {
              color: #fff;
              text-align: center;
            }

            /* 谷歌 */
            &::-webkit-input-placeholder {
              color: #fff;
              text-align: center;
            }

            /* 火狐 */
            &:-moz-placeholder {
              color: #fff;
              text-align: center;
            }

            /* ie */
            &:-ms-input-placeholder {
              color: #fff;
              text-align: center;
            }
          }
          .el-input__inner,
          .el-select__caret {
            height: auto;
            line-height: 30px;
            background-color: transparent;
            border: none;
          }
        }
      }
      .close {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        transition: all 0.3s;
        &:hover {
          background-color: #f56c6c;
        }
      }
      &:hover {
        border: 1px solid #ccc;
      }
    }
  }
  .act {
    color: #fff;
    background: #67c23a;
  }
  .custom-form {
    display: flex;
    align-items: center;
    .custom-alias-name {
      width: 160px;
      margin-right: 10px;
    }
    .operator-item {
      margin: 0 10px;
    }
    .custom-operator {
      width: 80px;
    }
  }
}
</style>
