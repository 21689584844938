<template>
  <div class="chart-config">
    <search-wrap>
      <div class="button-wrap">
        <el-form :inline="true" label-suffix="：">
          <el-form-item label="分类">
            <el-select v-model="search.categoryId" clearable @change="onChangeCategory">
              <el-option
                v-for="item in clasifyList"
                :key="item.categoryId"
                :value="item.categoryId"
                :label="item.categoryName"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="状态">
            <el-select v-model="search.status" clearable @change="onChangeCategory">
              <el-option :value="0" label="未发布" />
              <el-option :value="1" label="预发" />
              <el-option :value="2" label="已发布" />
            </el-select>
          </el-form-item>
          <el-form-item label="报表名称">
            <el-input
              v-model="search.modelName"
              clearable
              placeholder="请输入报表名称"
              @change="onChangeCategory"
            />
          </el-form-item>
          <el-button @click="onChangeCategory">搜索</el-button>
        </el-form>
        <div style="margin-bottom: 5px;">
          <el-button @click="showEditClassify = true">编辑分类</el-button>
          <el-button type="primary" @click="$router.push({name: 'StatementCreateOrEdit'})">新建数据报表</el-button>
        </div>
      </div>
    </search-wrap>
    <ul v-loading="loading" style="margin: 0; padding: 0;">
      <div v-if="reportList.length" class="wrap-list">
        <li v-for="item in reportList" :key="item.modelId" class="item">
          <div class="context" @click="onEdit(item)">
            <el-button
              v-if="item.status === 0"
              type="info"
              size="mini"
              icon="el-icon-error"
            >
              未发布
            </el-button>
            <el-button
              v-if="item.status === 1"
              type="warning"
              size="mini"
              icon="el-icon-remove"
            >
              预发
            </el-button>
            <el-button
              v-if="item.status === 2"
              type="success"
              size="mini"
              icon="el-icon-check"
            >
              已发布
            </el-button>
            <el-popover
              :disabled="!item.intro"
              placement="top"
              width="200"
              trigger="hover"
            >
              <div>{{ item.intro }}</div>
              <img
                slot="reference"
                class="cover"
                :src="coverUrl(item)"
                alt=""
              >
            </el-popover>
          </div>
          <div class="chart-info">
            <div class="title" @click="onEdit(item)">{{ item.modelName }}</div>
            <div class="desc">
              <span class="label">引用次数：</span>
              <span>{{ item.refCount }}</span>
            </div>
            <div class="desc">
              <span class="label">创建时间：</span>
              <span>{{ item.createdAt }}</span>
            </div>
            <div class="desc">
              <span class="label">更新时间：</span>
              <span>{{ item.updatedAt }}</span>
            </div>
            <div class="desc">
              <span class="label">分类名称：</span>
              <span>{{ item.categoryName }}</span>
            </div>
            <div class="desc">
              <span class="label">创建人：</span>
              <span>{{ item.creatorName }}</span>
            </div>
          </div>
          <p class="bottom">
            <el-button
              v-if="item.status === 0"
              type="primary"
              size="mini"
              @click="onPublish(item, 1)"
            >
              预发
            </el-button>
            <el-button
              v-if="item.status === 1"
              type="warning"
              size="mini"
              @click="onPublish(item, 2)"
            >
              发布
            </el-button>
            <el-button
              v-if="item.status === 2"
              type="danger"
              size="mini"
              @click="onPublish(item, 0)"
            >
              下线
            </el-button>
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              @click="onDel(item.modelId)"
            />
          </p>
        </li>
      </div>
      <div v-else class="empty">
        <el-empty description="暂无数据" />
      </div>

      <div style="margin: 10px auto; display: flex; justify-content: center;">
        <el-pagination
          center
          :current-page="pagination.pageNo"
          :total="pagination.total"
          :page-size="pagination.pageSize"
          :page-sizes="pagination.sizes"
          :layout="pagination.layout"
          :hide-on-single-page="false"
          background
          @size-change="onSizeChange"
          @current-change="onCurrentChange"
        />
      </div>
    </ul>
    <edit-classify ref="edit-classify" v-model="showEditClassify" :clasify-list.sync="clasifyList" />
  </div>
</template>
<script>
import paginationMixin from '@/mixins/pagination'
import EditClassify from './components/edit-classify.vue'
import { showTypeText } from '../utils.js'
import { getSmartReportFormList, deleteSmartModel, updateSmartModelStatus } from '@/apis/reportService.js'
export default {
  name: 'DataReportFormManagement',
  components: { EditClassify },
  mixins: [paginationMixin],
  data() {
    return {
      loading: false,
      showEditClassify: false,
      clasifyList: [],
      tableNameList: [],
      reportList: [],
      search: {
        categoryId: ''
      }
    }
  },
  computed: {
    showTypeText
  },
  mounted() {
    this.getDataList()

    this.$eventBus.$on('statement-list', this.getDataList)
  },
  methods: {
    async getDataList() {
      if (this.loading) return
      this.loading = true
      try {
        const params = { ...this.search }
        const { data = [], totalCount } = await getSmartReportFormList(this.getParams(params))
        this.reportList = data
        this.tableData = data
        this.pagination.total = totalCount
        this.loading = false
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    },
    onEdit({ modelId }) {
      this.$router.push({
        name: 'StatementCreateOrEdit',
        query: {
          modelId
        }
      })
    },
    // 删除报表
    async onDel(smartModelId) {
      try {
        await this.$confirm('此操作将删除该报表, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        const res = await deleteSmartModel({ smartModelId })
        if (res) {
          this.$message.success('删除成功')
          // this.search.pageNo = 1
          this.getDataList()
        }
      } catch (error) {
        console.log(error)
      }
    },
    async onPublish({ modelId }, status) {
      try {
        const msg = status === 0 ? '取消发布' : status === 2 ? '发布' : '预发'
        await this.$confirm(`此操作将${msg}该报表, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        const res = await updateSmartModelStatus({ smartModelId: modelId, status })
        if (res) {
          this.$message.success(`${msg}成功`)
          this.search.pageNo = 1
          this.getDataList()
        }
      } catch (error) {
        console.log(error)
      }
    },
    onChangeCategory() {
      this.isDisabled = false
      this.pagination.pageNo = 1
      this.getDataList()
    },

    coverUrl(item) {
      return item.cover ?? require(`@/assets/${showTypeText()(item.showType).coverImg}`)
    } 
  }
}
</script>

<style lang="scss" scoped>
.chart-config {
  height: calc(100vh - 40px);
  display: flex;
  flex-direction: column;
  .button-wrap {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .wrap-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
    gap: 15px;
    margin: 0;
    padding: 15px;
    background-color: #f3f4fa;
    .item {
      list-style: none;
      background-color: #fff;
      transition: all 0.2s linear;
      position: relative;
      border-radius: 8px;
      overflow: hidden;
      &:hover {
        z-index: 2;
        -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
        box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
        -webkit-transform: translate3d(0, -2px, 0);
        transform: translate3d(0, -2px, 0);
      }
      .context {
        height: 150px;
        position: relative;
        overflow: hidden;
        padding: 5px;
        box-sizing: border-box;
        border-bottom: 1px solid #ddd;
        >.el-button {
          zoom: 0.8;
          position: absolute;
          top: 5px;
          left: 5px;
          z-index: 1;
        }
        .cover {
          cursor: pointer;
          width: 100%;
          height: 100%;
          transition: all 0.4s;
          object-fit: cover;
          &:hover {
            transform: scale(1.3);
          }
        }
      }
      .chart-info {
        padding: 0 10px 2px 10px;
        .title {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-align: left;
          line-height: 26px;
          font-size: 14px;
          color: #333;
          font-weight: bold;
        }
        .desc {
          font-size: 12px;
          text-align: left;
          color: #aaa;
          display: block;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          margin: 3px 0 0 0;
        }
      }
      .bottom {
        margin-bottom: 10px;
        margin-top: 0;
        padding: 0 10px;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
  .empty {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
