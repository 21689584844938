<template>
  <div class="data-chart-wrap">
    <div class="place-row">
      <span>占位筛选项：</span>
      <span v-if="showPlaceItem">{{ '组织、' + placeString.join('、') }}
        <el-button type="text" @click="showPlaceModel">修改</el-button>
      </span>
      <el-button v-else type="text" @click="showPlaceModel">未设置，前往设置</el-button>
    </div>
    <div class="data-chart-title">
      <div class="tab">
        <el-button
          v-for="item in iconList"
          :key="item.value"
          size="mini"
          class="tab-item"
          :type="actChart.value === item.value ? 'success' : ''"
          :disabled="chartIsDisable(item.disabled)"
          :title="item.label"
          @click="changeShowType(item)"
        >
          <SvgIcon :name="item.value" />
        </el-button>
      </div>
      <div class="right-control">
        <el-button type="text" size="mini" @click="handleOrgId">上帝视角</el-button>
        <el-button
          size="mini"
          type="warning"
          plain
          @click="onExport"
        >
          导出数据
        </el-button>

        <el-select
          v-if="chartBaseData.desc.groupByDesc.length === 2 && typeMapShowGroupByDesc"
          value-key="xaxis"
          :value="propsXgroupByInfos"
          size="small"
          style="width: 200px;"
          @change="xGroupByInfosChange"
        >
          <el-option
            v-for="item in chartBaseData.desc.groupByDesc"
            :key="item.colDesc"
            :label="item.colDesc"
            :value="item"
          />
        </el-select>
      </div>
    </div>

    <div class="wrap-chart">
      <transition name="main" mode="out-in">
        <DataChart
          ref="data-chart"
          v-loading="dataChartLoading"
          :form-chart-option="form.chartOptions"
          :js-code-snippet="form.jsCodeSnippet"
          :show-type-name="actChart.value"
          :data="{ ...chartBaseData, modelName: form.modelName }"
        />
      </transition>
    </div>
  </div>
</template>

<script>
import {cloneDeep, debounce} from 'lodash-es'
import { iconList, showTypeText } from '../../utils.js'

export default {
  name: 'DataChartWrap',
  props: {
    value: {
      type: Object,
      default: Object
    },
    lastSaveForm: {
      type: Object,
      default: Object
    },
    activeIndex: Number
  },
  data() {
    return {
      chartBaseData: {
        data: [],
        desc: {
          colDesc: [],
          groupByDesc: []
        }
      },
      iconList,
      actChart: {},
      dataChartLoading: false,
      drawer: false
      // defaultChartOption
    }
  },
  computed: {
    form: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    propsXgroupByInfos() {
      const [row = {}] = this.chartBaseData.desc.groupByDesc?.filter(item => item.xaxis) || []
      return row
    },
    chartIsDisable() {
      return disabledFun => {
        if (!disabledFun) return
        const groupByInfosList = this.chartBaseData.desc.groupByDesc
        const selectInfosList = this.form.dataSourceQueryInfos.map(item => item.selectInfos).flat(Infinity)
        return disabledFun(selectInfosList, groupByInfosList)
      }
    },
    placeString() {
      const { placeholderInfos = [] } = this.form || {}
      let value = []
      placeholderInfos?.map(v => {
        value.push(v.colDesc)
      })
      return value
    },
    plcaeTimeList() {
      const { placeholderInfos = [] } = this.form || {}
      let value = []
      placeholderInfos?.map(v => {
        if (v.dataType === 3) {
          value.push(v)
        }
      })
      return value
    },
    showPlaceItem() {
      return this.form?.placeholderInfos?.length
    },
    reqChange() {
      const {dataSourceQueryInfos, queryInfos, calculateColInfos } = this.form
      return JSON.parse(JSON.stringify({
        dataSourceQueryInfos,
        queryInfos,
        calculateColInfos
      }))
    },
    showTypeText,
    typeMapShowGroupByDesc() {
      const {showType} = this.actChart
      return showType === 2 || showType === 1 || showType === 10
    }
  },
  watch: {
    reqChange: {
      async handler(newVal, oldVal) {
        // 如果只是改变了聚合项别名，直接中断
        let cloneNewVal = cloneDeep(newVal)
        let cloneOldVal = cloneDeep(oldVal)

        let formatCloneVal = [cloneNewVal, cloneOldVal].map(clone => {
          clone.dataSourceQueryInfos = clone.dataSourceQueryInfos.map(item => {
            item.selectInfos = item.selectInfos.map(selectInfosItem => {
              delete selectInfosItem.aliasName
              delete selectInfosItem.edit
              delete selectInfosItem.actSelect
              return selectInfosItem
            })
            return item
          })
          return clone
        })
        if (JSON.stringify(formatCloneVal[0]) === JSON.stringify(formatCloneVal[1])) {
          return
        }

        if (newVal.dataSourceQueryInfos?.length === 0) {
          this.chartBaseData = this.$options.data().chartBaseData
          return
        }

        if (newVal) {
          this.debounceGetData()
        }
      },
      deep: true,
      immediate: false
    },
    'form.showType': {
      handler(newVal) {
        const [row = this.iconList[0]] = this.iconList.filter(item => item.showType === newVal)
        this.actChart = row
      },
      immediate: true
    }
  },
  mounted() {
    this.debounceGetData = debounce(this.getData, 500)
  },
  methods: {
    // 查询报表数据
    async getData() {
      const params = cloneDeep(this.form)
      delete params.chartOptions
      delete params.jsCodeSnippet
      try {
        this.dataChartLoading = true
        const res = await this.$axios.post(this.$API.queryModelDataDirect, params)
        this.chartBaseData = res
        this.dataChartLoading = false

        // 处理当前图标展示状态
        const [row] = this.iconList.filter(item => item.showType === this.form.showType)
        if (this.chartIsDisable(row.disabled)) {
          this.actChart = this.iconList[0]
        }
      } catch (error) {
        this.dataChartLoading = false
        console.log(error)
        this.$message.error(error.message)
      }
    },
    showPlaceModel() {
      // eslint-disable-next-line vue/custom-event-name-casing
      this.$emit('showPlace', this.placeString, this.plcaeTimeList, this.form.placeholderInfos)
    },
    changeShowType(row) {
      this.actChart = row
      this.form.showType = row.showType
    },
    xGroupByInfosChange(row) {
      this.form.dataSourceQueryInfos = this.form.dataSourceQueryInfos.map(item => {
        item.groupByInfos = item.groupByInfos.map(groupByInfosItem => {
          if (row.colDesc === '时间') {
            if (groupByInfosItem.dataType === 3) {
              groupByInfosItem.showAxis = 1
            } else {
              groupByInfosItem.showAxis = 2
            }
          } else {
            if (groupByInfosItem.colDesc === row.colDesc) {
              groupByInfosItem.showAxis = 1
            } else {
              groupByInfosItem.showAxis = 2
            }
          }
          return groupByInfosItem
        })
        return item
      })
    },
    // 上帝模式
    handleOrgId() {
      this.form.queryInfos = [{ queryObjectId: 'system', queryObjectShowTitle: 'system' }]
    },

    // 双击事件
    onDblclick() {
      this.drawer = true
    },
    onExport() {
      this.$refs['data-chart'].exportExal()
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.data-chart-wrap {
  display: flex;
  flex-direction: column;
  .data-chart-title {
    height: 37px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // padding-bottom: 5px;
  }
  .tab {
    .tab-item {
      padding: 5px;
      &.el-button--success {
        background-color: #42b983;
        border-color: #42b983;
      }
    }
  }
  .right-control {
    display: flex;
    gap: 5px;
  }
  .wrap-chart {
    // flex: 1;
    height: calc(100% - 77px);
    // overflow: hidden;
    > div {
      height: calc(100% - 5px);
      // border-radius: 10px;
      padding: 10px;
      box-sizing: border-box;
      transition: all 0.3s;
      border: 1px solid #eee;
    }
  }
  .place-row {
    font-size: 14px;
  }
}
.preview {
  flex: 1;
  box-sizing: border-box;
  // overflow: hidden;
  padding-top: 5px;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  .icon-filter-switch {
    width: 20px;
    height: 20px;
    border-radius: 2px;
    background: #67c23a;
    color: #fff;
    padding: 4px;
    transition: all 0.3s;
    &:hover {
      background-color: rgba(122, 199, 94, 0.8);
    }
  }
  .special-group {
    height: 35px;
    display: grid;
    grid-template-columns: 25px 1fr auto;
    align-items: center;
    justify-content: space-between;
    .__vuescroll {
      flex: 1;
      flex-shrink: 0;
      :deep(.__view) {
        display: flex;
        align-items: center;
      }
    }
  }
  .chart-preview {
    display: flex;
    flex: 1;
    height: calc(100% - 45px);
    > div:nth-child(1) {
      flex: 1;
    }
  }
}

// 主内容区动画
.main-enter-active {
  transition: 0.2s;
}
.main-leave-active {
  transition: 0.15s;
}
.main-enter {
  opacity: 0;
  transform: translateX(20px);
}
.main-leave-to {
  opacity: 0;
  transform: translateX(20px);
}
</style>
