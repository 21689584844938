<template>
  <!-- 聚合 -->
  <div class="aggregation">
    <el-input v-model="filterName" placeholder="输入分组条件" />
    <div v-for="tag in filterReportDetailList" :key="tag.colName" class="group-list">
      <svg-icon :name="groupItemIcon(tag)" class="group-icon" />
      <el-tag
        class="check-list-item"
        :hit="false"
        :effect="tagAct(tag) ? 'dark' : 'plain'"
        :type="tagAct(tag) ? 'success' : 'info'"
        :closable="tagAct(tag)"
        @close="onRemoveGroupByInfos(tag)"
        @click="onAddGroupByInfos(tag)"
      >
        <span>{{ tag.colDesc }}</span>
        <el-select
          v-if="tagAct(tag) && groupItemIcon(tag) === 'date'"
          :value="groupByItemValue(tag).groupByItem"
          class="tag-range"
          placeholder="请选择"
          @change="(e) => onChangeGroupByItem(e, tag)"
        >
          <el-option
            v-for="item in dateRange"
            :key="item.value"
            :label="item.showText"
            :value="item.sqlText"
          />
        </el-select>
      </el-tag>
    </div>
    <!-- 时间分组 -->
    <div class="time-group group-list">
      <svg-icon name="date" class="group-icon" />
      <el-tag
        class="check-list-item"
        :hit="false"
        :effect="activeGroupByInfos.filter((v) => v.type === 'date').length === 0 ? 'plain' : 'dark'"
        :type="activeGroupByInfos.filter((v) => v.type === 'date').length === 0 ? 'info' : 'success'"
        :closable="activeGroupByInfos.filter((v) => v.type === 'date').length !== 0"
        @close="clearTimeGroupByInfos"
      >
        <span>时间分组</span>
        <span
          v-if="activeGroupByInfos.filter((v) => v.type === 'date').length === 0"
          style="color: #999ca1;"
          class="tag-range"
          @click="dialogVisible = true"
        >选择分组条件</span>
        <el-tooltip
          v-else
          effect="dark"
          :content="
            activeGroupByInfos
              .map((v) => (v.type === 'date' ? v.source.map((item) => item.colDesc) : null))
              .flat()
              .filter((v) => v)
              .join(',')
          "
          placement="top-start"
        >
          <span class="tag-range" @click="dialogVisible = true">{{
            activeGroupByInfos
              .map((v) => (v.type === 'date' ? v.source.map((item) => item.colDesc) : null))
              .flat()
              .filter((v) => v)
              .join(',')
          }}</span>
        </el-tooltip>
      </el-tag>
    </div>
    <!-- 组织分组 -->
    <div v-for="tag in orgList" :key="tag.colName" class="group-list">
      <svg-icon :name="groupItemIcon(tag)" class="group-icon" />
      <el-tag
        class="check-list-item"
        :hit="false"
        effect="dark"
        type="success"
      >
        <span>{{ tag.colDesc }}</span>
        <span v-if="chooseOrgName.length === 0" class="tag-range" @click="chooseOrg">选择分组条件</span>
        <el-tooltip v-else effect="dark" placement="top-start">
          <template #content>
            <div>
              <div v-for="(item, index) in splitArray(chooseOrgName, 3).map((item) => item.join(' , '))" :key="index" style="line-height: 18px;">
                {{ item }}
              </div>
            </div>
          </template>
          <span class="tag-range" @click="chooseOrg">{{ chooseOrgName.join(',') }}</span>
        </el-tooltip>
      </el-tag>
    </div>

    <el-dialog
      title="选择时间分组维度"
      class="time-group-dialog org-model"
      :visible.sync="dialogVisible"
      width="500px"
      @open="initTimeFormMap"
    >
      <el-radio-group v-model="dateRangeGroupByItem" size="small">
        <el-radio v-for="item in dateRange" :key="item.sqlMemberId" :label="item.sqlText">{{ item.showText }}</el-radio>
      </el-radio-group>
      <div style="margin-top: 20px;">
        <template v-for="dataSourceQueryInfo in form.dataSourceQueryInfos">
          <el-tag
            v-for="item in dataSourceQueryInfo.selectInfos"
            :key="dataSourceQueryInfo.customId + dataSourceQueryInfo.tableName + item.aliasName + item.sqlMemberId"
            disable-transitions
            class="tag-item"
            type="info"
          >
            <p class="select-label">
              <span>{{ item.aliasName }}</span>
              <span class="tooltip">{{ filterTableList(dataSourceQueryInfo.tableName) }}</span>
            </p>
            <el-select
              v-if="actTimeForm(dataSourceQueryInfo.customId)"
              :key="actTimeForm(dataSourceQueryInfo.customId).colDesc"
              placeholder="请选择"
              value-key="colDesc"
              :value="actTimeForm(dataSourceQueryInfo.customId)"
              size="small"
              style="margin-left: 20px;"
              @change="onAddTimeFormMap($event, dataSourceQueryInfo)"
            >
              <el-option
                v-for="dateRangeItem in actTimeForm(dataSourceQueryInfo.customId).timeGroupByList"
                :key="dateRangeItem.colDesc"
                :label="dateRangeItem.colDesc"
                :value="dateRangeItem"
              />
            </el-select>
          </el-tag>
        </template>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="onValidate">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="选择组织" :visible.sync="showOrgModel" class="org-model">
      <el-input v-model="searchOrgName" placeholder="请输入组织名称" />
      <!-- todo 回显问题-->
      <div v-loading="orgLoading" style="width: 100%; overflow-x: scroll;">
        <el-cascader-panel
          ref="demoCascader"
          v-model="valueList"
          class="demo-cascader"
          :props="props"
          :options="optionsList"
          @change="valueList = $event"
        >
          <template slot-scope="{data}">
            <span v-highlight="{ hWord: searchOrgName.replace(/ /g, '|'), word: data.name }" :title="data.name" />
          </template>
        </el-cascader-panel>
      </div>
      <div slot="footer" style="text-align: center;" class="dialog-footer">
        <el-button size="small" @click="showOrgModel = false">取 消</el-button>
        <el-button size="small" type="primary" @click="onChooseOrg">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { uniq, cloneDeep } from 'lodash-es'
import { getOrgList, getFullOrgList, getRequestSqlMemberList } from '@/apis/reportService.js'
export default {
  name: 'AggregationCom',
  props: {
    value: {
      type: Object,
      default: Object
    },
    activeIndex: Number,
    groupItemIcon: Function,
    filterTableList: Function
  },
  data() {
    return {
      searchOrgName: '',
      filterName: '',
      // 分组条件中日期范围
      dateRange: [],
      orgList: [{ colName: '组织', colDesc: '组织', dataType: 2 }],
      showOrgModel: false,
      chooseOrgList: [],
      reportDetailList: [],
      valueList: [],
      optionsList: [],
      // 当前选择的分组项
      activeGroupByInfos: [],
      dialogVisible: false,
      dateRangeGroupByItem: '',
      groupByItemDefaultVal: 'DATE_FORMAT($,\'%Y-%m\')',
      timeFormMap: [],
      copyDataSourceQueryInfos: [],
      props: {
        lazy: false,
        checkStrictly: true,
        value: 'id',
        label: 'name',
        multiple: true,
        emitPath: true,
        lazyLoad: async(node, resolve) => {
          const { value: orgId } = node
          let res = await this.getOrgList({ orgId })
          resolve(node.children?.length ? [] : res)
        }
      },
      orgLoading: false
    }
  },
  computed: {
    form: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    tagAct() {
      return tag =>
        this.activeGroupByInfos?.some(item => {
          return item.colDesc === tag.colDesc
        })
    },
    groupByItemValue() {
      return tag => {
        let [row] = this.activeGroupByInfos.filter(item => item.colDesc === tag.colDesc)
        return row
      }
    },
    groupByOrgValue() {
      return tag => {
        const [row] =
          this.form?.queryInfos?.filter(item => {
            return item.colName === tag.colName
          }) || []
        return row
      }
    },
    filterReportDetailList() {
      return this.reportDetailList.filter(item => {
        return item.dataType !== 3 && item.colDesc.includes(this.filterName)
      })
    },
    chooseOrgName: {
      get() {
        let chooseOrgName = []
        this.form.queryInfos.map(v => {
          if (v.queryObjectShowTitle) {
            chooseOrgName.push(v.queryObjectShowTitle)
          }
        })
        return chooseOrgName
      },
      set(newVal) {
        return newVal
      }
    },
    actTimeForm() {
      return customId => {
        let [row] = this.timeFormMap.filter(item => item.customId === customId)
        return row
      }
    }
  },
  watch: {
    'form.dataSourceQueryInfos': {
      handler(newVal) {
        const tableNames = uniq(newVal.map(item => item.tableName)).join(',')
        this.getPublicAggregation(tableNames)
      },
      immediate: true
    },
    // 切换时间选择范围
    dateRangeGroupByItem: {
      handler(newVal) {
        this.timeFormMap = this.timeFormMap.map(item => {
          item.groupByItem = newVal
          return item
        })
      },
      immediate: true
    },
    // 判断
    reportDetailList() {
      this.echoGroupByInfo()
    }
  },
  mounted() {
    this.getAggregation()
  },
  methods: {
    // 初始化 时间选择组件数据
    async initTimeFormMap() {
      for (let i = 0; i < this.form.dataSourceQueryInfos.length; i++) {
        let current = this.form.dataSourceQueryInfos[i]
        let flag = this.timeFormMap.some(item => item.customId === current.customId)
        if (!flag) {
          this.dateRangeGroupByItem = this.form.dataSourceQueryInfos[this.activeIndex || 0]?.groupByInfos.filter(item => item.dataType === 3)[0]?.groupByItem || this.groupByItemDefaultVal
          let obj = {
            customId: current.customId,
            groupByItem: this.dateRangeGroupByItem,
            tableName: current.tableName,
            colDesc: '',
            timeGroupByList: await this.getTimeAggregation(current.tableName)
          }
          this.timeFormMap.push(obj)
        }
      }
      this.activeGroupByInfos.forEach(item => {
        if (item.type === 'date') {
          item.source.forEach(sourceItem => {
            this.timeFormMap = this.timeFormMap.map(timeFormMapItem => {
              if (timeFormMapItem.customId === sourceItem.customId) {
                timeFormMapItem.colDesc = sourceItem.colDesc
                timeFormMapItem.colName = sourceItem.colName
                timeFormMapItem.dataType = sourceItem.dataType
              }
              return timeFormMapItem
            })
          })
        }
      })
    },
    // 回显分组项
    echoGroupByInfo() {
      this.activeGroupByInfos = []
      const { groupByInfos } = this.form.dataSourceQueryInfos[this.activeIndex]
      groupByInfos.forEach(groupByInfosItem => {
        if (groupByInfosItem.dataType !== 3) {
          const [row] = this.reportDetailList.filter(item => item.colDesc === groupByInfosItem.colDesc)
          this.activeGroupByInfos.push(row)
        } else {
          let obj = {
            type: 'date',
            source: this.form.dataSourceQueryInfos.map(dataSourceQueryInfosItem => {
              let [row] = dataSourceQueryInfosItem.groupByInfos.filter(item => item.dataType === 3)
              row.customId = dataSourceQueryInfosItem.customId
              return row
            })
          }
          this.activeGroupByInfos.push(obj)
        }
      })
      this.initTimeFormMap()
    },

    getCascadeValue() {
      if (this.form.queryInfos.length !== 0) {
        let data = []
        this.form.queryInfos.map(v => {
          data.push(v.queryObjectId)
        })
        data = data.filter(item => item !== 'system')
        getFullOrgList(data).then(res => {
          this.valueList = res.map(item => {
            return item.split(',')[0]
          })
          this.echoTissue(this.valueList)
        })
      }
    },
    // 回显组织数据
    async echoTissue(orgList = this.orgList) {
      let params = []
      if (typeof orgList === 'string') {
        params.push(orgList)
      } else {
        params = orgList
      }
      // 多个 各级需要 children 的list
      let arr = []
      // 回显用的父级链路
      let fullPath = []
      try {
        this.orgLoading = true
        if (params.length !== 0) {
          const res = await this.$axios.post(this.$API.getFullOrgList, params)
          fullPath = res?.map(item => item.split(',').reverse())
          const getTargetChildren = fullPath.map(item => item.slice(0, item.length - 1))
          let maxLength = Math.max(...getTargetChildren.map(item => item.length))
          for (let i = 0; i <= maxLength; i++) {
            arr.push([...new Set(getTargetChildren.map(fullPathItem => fullPathItem[i]).filter(fullPathItem => fullPathItem))])
            arr = arr.filter(item => item.length > 0)
          }
        }
      } catch (error) {
        console.log('%c [error]', 'font-size:15px; color:#42b983;', error)
      } finally {
        // 获取第一级组织
        let defaultOptions = await this.$axios.get(this.$API.getOrgList, { params: { orgId: '' } })
        await this.formatBaseOptionList(defaultOptions, arr)

        this.$set(this, 'optionsList', defaultOptions)
        if (params.length === 1 && !this.props.multiple) {
          fullPath = fullPath.flat(1)
        }

        this.valueList = fullPath
        this.$nextTick(() => {
          this.props.lazy = true
          this.props.emitPath = true
          this.$refs.demoCascader.initStore()
        })
        this.orgLoading = false
      }
    },
    async formatBaseOptionList(options, fullPath, index = 0) {
      const current = fullPath[index]
      for (let i = 0; i < options.length; i++) {
        options[i].children = []
        // 单选和多选时，判断是否需要获取children
        if (current && ((current.includes(options[i].id) && index < fullPath.length))) {
          options[i].children = await this.$axios.get(this.$API.getOrgList, { params: { orgId: options[i].id } })
          await this.formatBaseOptionList(options[i].children, fullPath, index + 1)
        }
      }
    },
    chooseOrg() {
      this.getCascadeValue()
      this.showOrgModel = true
    },
    onChooseOrg() {
      const obj = this.$refs['demoCascader'].getCheckedNodes()
      this.chooseOrgList = []
      let nameList = []
      obj.map(({ data: { id, name } }) => {
        this.chooseOrgList.push({ queryObjectId: id, queryObjectShowTitle: name })
        nameList.push(name)
      })
      this.chooseOrgName = nameList
      this.form = { ...this.form, queryInfos: this.chooseOrgList }
      this.showOrgModel = false
    },
    // 获取orglist
    async getOrgList(params) {
      this.loading = true
      try {
        const res = await getOrgList(params)
        this.loading = false
        return res.map(item => {
          item.children = []
          return item
        })
      } catch (error) {
        console.log(error)
        this.loading = false
      }
    },
    // 聚合条件
    async getAggregation() {
      try {
        const res = await getRequestSqlMemberList({ type: 4 })
        this.dateRange = res
      } catch (error) {
        console.log(error)
      }
    },
    // 公共分组项
    async getPublicAggregation(tableNames) {
      try {
        if (!tableNames) return
        this.reportDetailList = await this.$axios.get(this.$API.getSameColumnList, { params: { tableNames } })
      } catch (error) {
        console.log(error)
      }
    },
    // 获取时间分组
    async getTimeAggregation(tableNames) {
      try {
        const res = await this.$axios.get(this.$API.getSameColumnList, { params: { tableNames } })
        return res.filter(item => item.dataType === 3)
      } catch (error) {
        this.$message.error(error.message)
      }
    },
    onAddGroupByInfos(tag) {
      let flag = this.activeGroupByInfos.some(item => item.colDesc === tag.colDesc)
      // if (this.activeGroupByInfos.length === 2 && !flag) {
      //   this.$message.warning('最多只能选两个分组条件')
      //   return false
      // }
      if (!flag) {
        // tag.groupByItem = tag.groupByItem || tag.dataType === 3 ? this.groupByItemDefaultVal : null
        this.activeGroupByInfos.push(tag)
        this.updateGroupByInfos()
      }
    },
    // 删除分组项
    onRemoveGroupByInfos(tag) {
      this.activeGroupByInfos = this.activeGroupByInfos.filter(item => item.colDesc !== tag.colDesc)
      this.updateGroupByInfos()
    },
    // 时间分组项修改范围
    onChangeGroupByItem(groupByItem, tag) {
      this.activeGroupByInfos = this.activeGroupByInfos.map(item => {
        item.groupByItem = item.colDesc === tag.colDesc ? groupByItem : this.groupByItemDefaultVal
        return item
      })
    },
    onAddTimeFormMap(groupByInfo, { customId, tableName }) {
      this.timeFormMap = this.timeFormMap.map(item => {
        if (item.customId === customId) {
          item.colDesc = groupByInfo.colDesc
          item.dataType = groupByInfo.dataType
          groupByInfo.tableAndColNames?.forEach(tableAndColNamesItem => {
            if (tableName === tableAndColNamesItem.tableName) {
              item.colName = tableAndColNamesItem.colName
            }
          })
        }
        return item
      })
    },
    // 验证时间分组项是否正确
    onValidate() {
      let flag = this.form.dataSourceQueryInfos.some(({ customId }) => {
        return this.timeFormMap.some(item => item.customId === customId && (!item.groupByItem || !item.dataType || !item.colDesc || !item.colName || !item.tableName))
      })
      if (flag) {
        this.$message.error('请选择对应聚合项的时间筛选器')
      } else {
        this.activeGroupByInfos = this.activeGroupByInfos.filter(item => item.type !== 'date')
        // if (this.activeGroupByInfos.length === 2) {
        //   this.$message.warning('最多只能选两个分组条件')
        //   return false
        // }
        let obj = cloneDeep(this.timeFormMap)
        for (let key in obj) {
          delete obj[key].timeGroupByList
          delete obj[key].tableName
          delete obj[key].tableAndColNames
        }
        this.activeGroupByInfos.push({ type: 'date', source: obj })
        this.dialogVisible = false
        this.updateGroupByInfos()
      }
    },
    // 更新分组项
    updateGroupByInfos() {
      this.form.dataSourceQueryInfos = this.form.dataSourceQueryInfos.map(dataSourceQueryInfosItem => {
        dataSourceQueryInfosItem.groupByInfos = this.activeGroupByInfos.map((newValItem, index) => {
          if (newValItem.type === 'date') {
            let [row] = newValItem.source.filter(item => item.customId === dataSourceQueryInfosItem.customId)
            row['showAxis'] = index + 1
            return row
          } else {
            let obj = {
              colName: '',
              colDesc: newValItem.colDesc,
              dataType: newValItem.dataType,
              groupByItem: newValItem.groupByItem || (newValItem.dataType === 3 ? this.groupByItemDefaultVal : null),
              showAxis: newValItem.showAxis || index + 1 || 1
            }
            newValItem.tableAndColNames?.forEach(tableAndColNamesItem => {
              if (tableAndColNamesItem.tableName === dataSourceQueryInfosItem.tableName) {
                obj.colName = tableAndColNamesItem.colName
              }
            })
            return obj
          }
        })
        return dataSourceQueryInfosItem
      })
    },
    // 清除时间分组项
    clearTimeGroupByInfos() {
      this.activeGroupByInfos = this.activeGroupByInfos.filter(item => item.type !== 'date')
      this.timeFormMap = []
      this.updateGroupByInfos()
    },
    splitArray(arr, num) {
      const result = []
      let temp = []
      for (let i = 0; i < arr.length; i++) {
        temp.push(arr[i])
        if ((i + 1) % num === 0) {
          result.push(temp)
          temp = []
        }
      }
      if (temp.length > 0) {
        result.push(temp)
      }
      return result
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.aggregation {
  .conditions {
    .tooltip {
      color: #aaa;
      font-size: 12px;
    }
    .aggregation-item {
      padding: 5px;
    }
  }
  .group-list {
    display: flex;
    align-items: center;
    .group-icon {
      margin-right: 5px;
      flex-shrink: 1;
    }
    .check-list-item {
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 4px;
      padding-right: 10px;
      border-radius: 2px;
      transition: all 0.2s;
      margin: 2px 0;
      font-size: 14px;
      border: 1px solid transparent;
      overflow: hidden;
      span {
        cursor: pointer;
        flex-shrink: 1;
        overflow-x: auto;
      }
      .tag-range {
        color: #fff;
        width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        :deep(.el-input) {
          height: 30px;
          font-size: 0;
          box-sizing: border-box;
          .el-input__inner {
            width: 100px;
            font-size: 14px;
            color: #fff;
            &::placeholder {
              color: #fff;
              text-align: center;
            }

            /* 谷歌 */
            &::-webkit-input-placeholder {
              color: #fff;
              text-align: center;
            }

            /* 火狐 */
            &:-moz-placeholder {
              color: #fff;
              text-align: center;
            }

            /* ie */
            &:-ms-input-placeholder {
              color: #fff;
              text-align: center;
            }
          }
          .el-input__inner,
          .el-select__caret {
            height: auto;
            line-height: 30px;
            background-color: transparent;
            border: none;
          }
        }
      }
      .close {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        transition: all 0.3s;
        &:hover {
          background-color: #f56c6c;
        }
      }
      &:hover {
        border: 1px solid #ccc;
      }
    }
  }
  .act {
    color: #fff;
    background: #67c23a;
  }
  .org-model {
    :deep(.el-dialog__body) {
      border: solid 1px #e4e7ed;
    }
    .demo-cascader {
      :deep(.el-cascader-menu) {
        flex-shrink: 0;
        .el-cascader-menu__wrap {
          height: 400px;
          max-width: 230px;
        }
      }
    }
    :deep(.el-cascader-panel).is-bordered {
      border: none;
    }
  }
  :deep(.time-group-dialog) {
    .el-dialog__body {
      .tag-item {
        padding-right: 0;
      }
    }
    .dialog-footer {
      text-align: center;
    }
  }
}
</style>
