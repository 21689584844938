<template>
  <el-dialog class="edit-classify" :visible.sync="visible" title="编辑报表分类">
    <el-form
      ref="tableForm"
      v-loading="addOrEditLoading"
      :model="{ tableData }"
      :rules="tableRules"
      size="small"
    >
      <el-table
        ref="table"
        class="classify"
        :data="tableData"
        height="400px"
        border
      >
        <el-table-column prop="categoryName" label="分类名称">
          <template slot-scope="scoped">
            <el-form-item :prop="`tableData.${scoped.$index}.categoryName`" :rules="tableRules.categoryName">
              <el-input
                v-if="scoped.row.type === 'edit'"
                v-model="scoped.row.categoryName"
                placeholder="请填写分类名称"
                @keyup.enter.native.prevent="onSave(scoped.row)"
              />
              <span v-else>{{ scoped.row.categoryName }}</span>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column width="120px" prop="isPublish" label="发布状态">
          <template slot-scope="scoped">
            {{ scoped.row.isPublish ? '已发布' : '未发布' }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="240px">
          <template slot-scope="scoped">
            <el-button size="small" type="primary" @click="handlePublish(scoped.row)">{{ scoped.row.isPublish ? '取消发布':'发布' }}</el-button>
            <el-button v-if="scoped.row.type === 'edit'" size="small" @click="onSave(scoped.row)">保存</el-button>
            <el-button
              v-else
              size="small"
              :disabled="scoped.row.isPublish"
              @click="onEdit(scoped.row)"
            >
              编辑
            </el-button>
            <el-button
              v-if="scoped.row.categoryId === ''"
              size="small"
              type="danger"
              @click="onDel(scoped.row)"
            >
              删除
            </el-button>
            <el-popconfirm
              v-else
              :title="`确定删除【${scoped.row.categoryName}】分类吗？`"
              confirm-button-type="danger"
              style="margin-left: 10px;"
              @confirm="onDel(scoped.row)"
            >
              <el-button slot="reference" size="small" type="danger">删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <el-button
      type="primary"
      style="width: 100%; margin: 5px 0; text-align: center;"
      icon="el-icon-plus"
      @click="onAdd"
    >
      添加
    </el-button>
  </el-dialog>
</template>

<script>
import { saveOrUpdateCategory, getListCategory, deleteCategory } from '@/apis/reportService.js'
export default {
  name: 'EditClassify',
  components: {},
  props: {
    value: {
      type: Boolean,
      default: false
    },
    clasifyList: {
      type: Array,
      default: function() {
        return this.tableData
      }
    }
  },
  data() {
    return {
      addOrEditLoading: true,
      tableData: [],
      tableRules: {
        categoryName: [{ required: true, message: '分类名称不能为空', trigger: 'blur' }]
      }
    }
  },
  computed: {
    visible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  mounted() {
    this.getDataList()
  },
  methods: {
    async getDataList() {
      try {
        this.addOrEditLoading = true
        const res = await getListCategory({
          pageNo: 1,
          pageSize: 99999
        })
        this.tableData = res.map(item => {
          item.type = ''
          return item
        })
        this.$emit('update:clasifyList', res)
        this.addOrEditLoading = false
      } catch (error) {
        this.addOrEditLoading = false
        console.log(error)
      }
    },
    onAdd() {
      const flag = this.tableData?.some(item => item.categoryId === '')
      if (!flag) {
        this.$refs.table.bodyWrapper.scrollTop = 0
        this.tableData?.unshift({ categoryId: '', type: 'edit' })
      }
    },
    onEdit(row) {
      this.tableData = this.tableData.map(item => {
        item.type = item.categoryId === row.categoryId ? 'edit' : ''
        return item
      })
    },
    onSave(row) {
      // 验证表单
      this.$refs.tableForm.validate(async valid => {
        if (valid) {
          try {
            this.addOrEditLoading = true
            const params = Object.assign({}, row)
            const res = await saveOrUpdateCategory(params)
            if (res) {
              this.$message.success('保存成功！')
              this.getDataList()
            }
            this.addOrEditLoading = false
          } catch (error) {
            this.addOrEditLoading = false
          }
          this.resetForm()
        } else {
          console.log('error')
          return false
        }
      })
    },
    async onDel(row) {
      if (row.categoryId === '') {
        this.tableData = this.tableData.filter(item => item.categoryId !== row.categoryId)
        this.resetForm()
      } else {
        const res = await deleteCategory({ categoryId: row.categoryId })
        this.getDataList()
        if (res) {
          this.resetForm()
          this.$message.success('删除成功')
        }
      }
    },
    // 处理发布
    async handlePublish(row) {
      try {
        const params = {
          categoryId: row.categoryId,
          publishStatus: !row.isPublish
        }
        await this.$axios({
          method: 'post',
          url: this.$API.changeCategoryStatus,
          params
        })
        this.getDataList()
      } catch (error) {
        console.log('%c [error]', 'font-size:15px; color:#42b983;', error)
      }
    },
    resetForm() {
      this.$refs.tableForm.resetFields()
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.edit-classify {
  .classify {
    :deep(.el-form-item) {
      margin-bottom: 14px;
    }
  }
  :deep(.el-table) .el-table__cell {
    padding-bottom: 0;
    &:nth-child(2) {
      padding-bottom: 12px;
    }
  }
}
</style>
