<template>
  <div class="edit-form-title">
    <el-form ref="titleForm" label-suffix="：" label-width="80px">
      <el-form-item required label="表名">
        <el-input v-model="titleForm.modelName" placeholder="请输入报表名称" style="max-width: 217px;" />
      </el-form-item>
      <el-form-item label="分类">
        <el-select
          v-model="titleForm.categoryId"
          clearable
          multiple
          collapse-tags
          filterable
          placeholder="请选择分类"
        >
          <el-option
            v-for="item in classifyList"
            :key="item.categoryId"
            :value="item.categoryId"
            :label="item.categoryName"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="简介">
        <el-input
          v-model="titleForm.intro"
          placeholder="请输入简介"
          type="textarea"
          :rows="3"
        />
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'EditFormTitle',
  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      classifyList: []
    }
  },
  computed: {
    titleForm: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  mounted() {
    this.getClassifyDataList()
  },
  methods: {
    // 分类列表
    async getClassifyDataList() {
      try {
        const res = await this.$axios.get(this.$API.getListCategory, {params: {
          pageNo: 1,
          pageSize: 99999
        }})
        this.classifyList = res
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>
