<template>
  <div class="layout">
    <div class="tab-icon">
      <div>
        <svg-icon name="json" :class="`svg-icon ${showGenreForm === 'json' ? 'act' : ''}`" @click="showGenreForm = 'json'" />
        <svg-icon name="form-table" :class="`svg-icon ${showGenreForm === 'form-table' ? 'act' : ''}`" @click="showGenreForm = 'form-table'" />
      </div>
      <div>
        <el-button size="mini" style="margin-left: 5px;" @click="resetConfig"> 重置配置 </el-button>
        <el-button size="mini" style="margin-left: 5px;" @click="mergeConfig"> 合并配置 </el-button>
      </div>
    </div>
    <div v-if="reloadConfigCom" class="wraper">
      <div>
        <AceCodeEditor
          v-if="showGenreForm === 'json'"
          ref="aceCodeEditorForm"
          v-model="formStr"
          :options="{ mode: 'ace/mode/json' }"
        />
        <vue-scroll-bar v-if="showGenreForm === 'form-table'" class="right-control-bar">
          <div style="padding: 5px;"><DeepForm v-model="form" /></div>
        </vue-scroll-bar>
      </div>

      <div>
        <div class="title">js代码片段，处理输出值</div>
        <AceCodeEditor v-if="showGenreForm === 'json'" ref="aceCodeEditorJsCodeSnippetStr" v-model="jsCodeSnippetStr" />
      </div>
    </div>
  </div>
</template>

<script>
import { showTypeText } from '../../utils.js'
import { js_beautify } from 'js-beautify'
import DeepForm from './deep-form.vue'
import { defaultChartOption } from '../../../../components/DataChart/utils/config.js'

export default {
  name: 'RightControlBar',
  components: { DeepForm },
  props: {
    chartOptions: {
      type: Object,
      require: true
    },
    showType: {
      type: Number
    },
    jsCodeSnippet: {
      type: String
    }
  },
  data() {
    return {
      showGenreForm: 'json',
      form: {},
      jsCodeSnippetStr: '',
      reloadConfigCom: true,
      beautifyOpt: { indent_size: 2 }
    }
  },
  computed: {
    showTypeText,
    formStr: {
      get() {
        return js_beautify(JSON.stringify(this.form, null, 2).trim(), this.beautifyOpt)
      },
      set(val) {
        try {
          if (typeof val === 'string') {
            this.$set(this, 'form', JSON.parse(val))
          }
        } catch (error) {
          // console.log(error)
        }
      }
    },
    chartIsDisable() {
      return disabledFun => {
        if (!disabledFun) return
        const { selectInfos, groupByInfos } = this.form
        const flag = disabledFun(selectInfos, groupByInfos)
        return flag
      }
    }
  },
  watch: {
    showType: {
      handler() {
        this.reloadConfigCom = false
        this.$nextTick(() => {
          this.form = this.chartOptions
          this.jsCodeSnippetStr = js_beautify(this.jsCodeSnippet, this.beautifyOpt)
          this.reloadConfigCom = true
        })
      },
      immediate: true
    }
  },
  mounted() {
    this.form = this.chartOptions
    this.jsCodeSnippetStr = js_beautify(this.jsCodeSnippet, this.beautifyOpt)
  },
  methods: {
    mergeConfig() {
      this.$emit('update:chart-options', this.form)
      this.$emit('update:js-code-snippet', this.jsCodeSnippetStr)
    },
    resetConfig() {
      this.$emit('update:chart-options', defaultChartOption[this.showTypeText(this.showType).value])
      this.form = defaultChartOption[this.showTypeText(this.showType).value]
    }
  }
}
</script>

<style lang="scss" scoped>
.layout {
  height: 100%;
  display: flex;
  flex-direction: column;
  .wraper {
    flex: 1;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    > div {
      height: calc(50% - 15.5px);
    }
    .title {
      height: 31px;
      line-height: 31px;
      border-left: 2px solid #008075;
      border-top: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
      padding-left: 10px;
      font-size: 12px;
    }
  }
  .tab-icon {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    border-bottom: 1px solid #eee;
    .el-button {
      border-radius: 0;
    }
    .svg-icon.act {
      color: #333;
    }
    .svg-icon {
      padding: 4px;
      font-size: 30px;
      color: #bababa;
      cursor: pointer;
      transition: all 0.3s;
      &:hover {
        color: #333;
      }
    }
  }
}
</style>
