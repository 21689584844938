<template>
  <!-- filter-conditions -->
  <div class="conditions">
    <div class="conditions-title-button">
      <el-button icon="el-icon-arrow-left" type="text" @click="onPrev">返回</el-button>
      <el-tag type="mini">{{ value.colDesc }}</el-tag>
    </div>
    <el-form
      ref="el-form"
      :model="whereInfos"
      :rules="rules"
      class="int-filter-wrap"
    >
      <el-form-item prop="methodsValue">
        <el-select
          v-model="whereInfos.methodsValue"
          style="width: 100%;"
          value-key="sqlMemberId"
          placeholder="请选择"
          @change="methodsValueChange"
        >
          <el-option
            v-for="item in methodsList"
            :key="item.sqlMemberId"
            :label="item.showText"
            :value="item.sqlMemberId + ',' + item.showText"
          />
        </el-select>
      </el-form-item>
      <!-- 为空和不为空取消value -->
      <template v-if="!isNullOrNotNull">
        <el-form-item v-if="type === 'date'" prop="value">
          <el-date-picker
            v-model="whereInfos.value"
            style="width: 100%;"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择日期"
            :picker-options="pickerOptions"
          />
        </el-form-item>
        <el-form-item v-else-if="type === 'string'" prop="value">
          <el-autocomplete
            v-model.trim="whereInfos.value"
            style="width: 100%;"
            class="inline-input"
            :fetch-suggestions="querySearch"
            placeholder="请输入内容"
            :popper-append-to-body="false"
            :trigger-on-focus="true"
          >
            <template slot-scope="{item}">
              <div class="select-row-style" :style="`grid-template-columns: repeat(${item.label != item.value ? 2: 1}, 1fr);`">
                <span>{{ item.value }}</span>
                <span v-if="item.label != item.value" style="color: #8492a6; font-size: 13px;">{{ item.label }}</span>
              </div>
            </template>
          </el-autocomplete>
        </el-form-item>
        <el-form-item v-else-if="colValuesList.length" prop="value">
          <el-autocomplete
            v-model.trim="whereInfos.value"
            style="width: 100%;"
            class="inline-input"
            :fetch-suggestions="querySearch"
            placeholder="请输入内容"
            :popper-append-to-body="false"
            :trigger-on-focus="true"
          >
            <template slot-scope="{item}">
              <div class="select-row-style" :style="`grid-template-columns: ${item.label != item.value ? 1 : null}fr auto`">
                <span>{{ item.value }}</span>
                <span v-if="item.label != item.value" style="color: #8492a6; font-size: 13px;">{{ item.label }}</span>
              </div>
            </template>
          </el-autocomplete>
          <span v-if="isContainsOrNotContains" style="font-size: 12px; line-height: 1; color: rgb(129, 129, 129);">示例 1,2,3,4</span>
        </el-form-item>
      </template>
      <el-form-item>
        <el-button
          style="width: 100%;"
          icon="el-icon-plus"
          type="primary"
          @click="onSaveFilter"
        >
          添加过滤器
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {cloneDeep} from 'lodash-es'
import { getRequestSqlMemberList } from '@/apis/reportService.js'
export default {
  name: 'Conditions',
  components: {},
  props: {
    type: {
      type: String,
      default: String
    },
    value: {
      type: Object,
      default: Object
    },
    form: {
      type: Object,
      default: Object
    },
    activeIndex: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      methodsList: [],
      whereInfos: {
        methodsValue: '',
        value: ''
      },
      descList: [],
      colValues: [],
      rules: {
        methodsValue: [{ required: true, message: '请选择一个条件', trigger: 'change' }],
        value: [{ required: true, message: '请输入', trigger: 'blur' }]
      },
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick: picker => {
            picker.$emit('pick', new Date(this.$moment().add(-1, 'week')))
          }
        }, {
          text: '最近一个月',
          onClick: picker => {
            picker.$emit('pick', new Date(this.$moment().add(-1, 'month')))
          }
        }, {
          text: '最近三个月',
          onClick: picker => {
            picker.$emit('pick', new Date(this.$moment().add(-3, 'month')))
          }
        }]
      }
    }
  },
  computed: {
    colValuesList() {
      let arr = [ ...this.descList.map(item => {
        return {
          value: `${item.descListValue}`,
          label: item.label
        }
      }), ...this.colValues.map(item => {
        return {
          value: `${item}`,
          label: item
        }
      }) ]
      return arr
    },
    isNullOrNotNull() {
      const [sqlMemberId = ''] = this.whereInfos.methodsValue?.split(',')
      return this.methodsList.some(item => {
        if (item.sqlMemberId === sqlMemberId) {
          return item.showText === '为空' || item.showText === '不为空'
        } else {
          return false
        }
      })
    },
    isContainsOrNotContains() {
      const [sqlMemberId = ''] = this.whereInfos.methodsValue?.split(',')
      return this.methodsList.some(item => {
        if (item.sqlMemberId === sqlMemberId) {
          return item.showText === '包含' || item.showText === '不包含'
        }
      })
    }
  },
  mounted() {
    const [row] = this.form.dataSourceQueryInfos[this.activeIndex]?.whereInfos
    if (row && row.colName === this.value.colName) {
      this.whereInfos.value = row.value
      this.whereInfos.methodsValue = `${row.sqlMemberId  },${  row.symbolDesc}`
    }
    this.getMethodsList()

    this.getModelDataByWhere()
  },
  methods: {
    async getModelDataByWhere() {
      if (!this.value.colName || !this.form.dataSourceQueryInfos[this.activeIndex].tableName) return
      try {
        const params = cloneDeep(this.form)
        if (!this.value.aggregateSqlMemberId) {
          params.dataSourceQueryInfos[this.activeIndex].groupByInfos = [
            {
              colName: this.value.colName,
              colDesc: this.value.colDesc,
              showAxis: 1
            }
          ]
        }
        params.dataSourceQueryInfos[this.activeIndex].whereInfos = params.dataSourceQueryInfos[this.activeIndex].whereInfos.filter(item => item.colName !== this.value.colName)

        params.dataSourceQueryInfos = [params.dataSourceQueryInfos[this.activeIndex]]
        let data = {}
        delete params.chartOptions
        if (this.value.aggregateSqlMemberId) {
          data = await this.$axios.post(this.$API.queryModelDataByHaving, params)
        } else {
          data = await this.$axios.post(this.$API.queryModelDataByWhere, params)
        }
        let { desc, colValues } = data
        if (desc.colValues) {
          let arr = []
          desc?.colValues?.split(',').forEach(item => {
            const [key, desc] = item?.split(':')
            arr.push({
              descListValue: key,
              label: desc
            })
          })
          this.descList = arr
        } else if (colValues) {
          this.colValues = colValues.filter(item => item)
        }
      } catch (error) {
        console.log(error)
      }
    },
    // 添加过滤器
    onSaveFilter() {
      this.$refs['el-form'].validate(valid => {
        if (valid) {
          const [sqlMemberId = '', symbolDesc = ''] = this.whereInfos.methodsValue?.split(',')
          const { colName, colDesc, dataType, value } = { ...this.whereInfos, ...this.value }
          let valueDesc = ''
          if (!this.isNullOrNotNull) {
            if (this.type === 'int' && !!this.descList.length && !this.isContainsOrNotContains) {
              valueDesc = Number(value)
            } else {
              valueDesc = value
            }
          }
          // eslint-disable-next-line vue/custom-event-name-casing
          this.$emit('onSaveFilter', { colName, aggregateSqlMemberId: this.value.aggregateSqlMemberId, colDesc, sqlMemberId, dataType, symbolDesc, value: valueDesc })
          this.onPrev()
        } else {
          return false
        }
      })
    },
    async getMethodsList() {
      try {
        const res = await getRequestSqlMemberList({ type: 1 })
        this.methodsList = res
      } catch (error) {
        console.log(error)
      }
    },
    methodsValueChange() {
      if (this.isContainsOrNotContains && this.type === 'int') {
        // 正则匹配 1,2,3,-0.1,4 不以逗号结尾
        this.rules.value.push({ pattern: new RegExp(/^((-)?\d+(\.\d+)?[,]?)+$/, 'g'), message: '请输入正确的格式', trigger: 'blur' })
      } else {
        this.rules.value.splice(1, 1)
      }
    },
    querySearch(queryString, cb) {
      let restaurants = this.colValuesList
      let results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    createFilter(queryString) {
      return restaurant => {
        const {value} = restaurant
        return (String(value).toLowerCase().indexOf(String(queryString).toLowerCase()) === 0)
      }
    },
    onPrev() {
      this.whereInfos = {}
      this.$emit('input', {})
      this.$refs['el-form'].resetFields()
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.conditions {
  width: 100%;
  .conditions-title-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .int-filter-wrap {
    width: 100%;
  }
  .select-row-style {
    display: grid;
    gap: 10px;
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
