<template>
  <div class="create-wrap">
    <div class="create-chart">
      <div class="controller" :style="`width: ${type === '' ? '0px' : '300px'}`">
        <vue-scroll-bar>
          <div style="padding-right: 10px;">
            <p class="report-title"><svg-icon name="title-label" /> 聚合条件</p>
            <div class="conditions">
              <p v-if="form.dataSourceQueryInfos.length" class="tooltip">双击聚合项更改名称</p>
              <!-- 聚合项 -->
              <draggable
                v-model="form.dataSourceQueryInfos"
                v-bind="dragOptions"
                handle=".handle"
                @start="drag = true"
                @end="drag = false"
              >
                <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                  <template v-for="dataSourceQueryInfo in form.dataSourceQueryInfos">
                    <template v-for="(item, index) in dataSourceQueryInfo.selectInfos">
                      <el-tag
                        :key="dataSourceQueryInfo.customId + dataSourceQueryInfo.tableName + item.sqlMemberId"
                        :title="`${dataSourceQueryInfo.tableName}=${item.colName}=${aggregationList.filter((aggregationItem) => aggregationItem.sqlMemberId === item.sqlMemberId)[0]?.showText}`"
                        disable-transitions
                        class="tag-item"
                        :type="dataSourceQueryInfo.customId === activeDataSourceQueryInfo.customId ? '' : 'info'"
                        :effect="dataSourceQueryInfo.customId === activeDataSourceQueryInfo.customId ? 'dark' : null"
                        closable
                        @dblclick.native="onEdit(dataSourceQueryInfo, item, 'edit')"
                        @click="onEdit(dataSourceQueryInfo, item)"
                        @close="tagDel(dataSourceQueryInfo, item)"
                      >
                        <p class="select-label">
                          <span>
                            <svg-icon name="move" class="handle" />
                            <span v-if="!item.edit">{{ item.aliasName }}</span>
                            <el-input
                              v-else
                              :key="dataSourceQueryInfo.customId + dataSourceQueryInfo.tableName + item.colName + index + item.sqlMemberId"
                              ref="saveTagInput"
                              v-model="item.aliasName"
                              class="input-new-tag"
                              size="small"
                              @blur="onEdit(dataSourceQueryInfo, item, 'edit')"
                            />
                          </span>
                          <span class="tooltip">{{ filterTableList(dataSourceQueryInfo.tableName) }}</span>
                        </p>
                      </el-tag>
                    </template>
                  </template>
                </transition-group>
              </draggable>
              <p v-if="form.calculateColInfos.length" class="tooltip">双击自定义列修改</p>
              <template v-for="item in form.calculateColInfos">
                <el-tag
                  v-if="!item.edit"
                  :key="JSON.stringify({...item, aliasName:''})"
                  disable-transitions
                  class="tag-item"
                  type="info"
                  closable
                  @dblclick.native="onCalculateColInfosEdit(item)"
                  @close="calculateColInfosTagDel(item)"
                >
                  <p class="select-label">
                    <span>
                      <!-- <svg-icon name="move" class="handle" /> -->
                      <span>{{ item.aliasName }}</span>
                    </span>
                    <span class="tooltip">自定义列</span>
                  </p>
                </el-tag>
              </template>
              <div style="display: flex; gap: 5px; width: 100%;">
                <el-button
                  style="width: 50%;"
                  type="warning"
                  icon="el-icon-plus"
                  @click="customDialogVisible = !customDialogVisible"
                >
                  自定义列
                </el-button>
                <SelectInfos
                  style="flex: 1;"
                  :aggregation-list="aggregationList"
                  :table-list="tableList"
                  @beforeClose="onAddSelectInfos"
                >
                  <el-button style="width: 100%;" type="primary" icon="el-icon-plus">添加</el-button>
                </SelectInfos>
              </div>
            </div>
          </div>

          <el-tabs slot="vue-scroll-bar-wrap" v-model="type" class="tabs">
            <el-tab-pane label="排序" name="rank-com">
              <RankCom v-model="form.dataSourceQueryInfos[activeIndex]" :group-item-icon="groupItemIcon" :report-detail-list="reportDetailList" />
            </el-tab-pane>
            <el-tab-pane label="过滤器" name="filter-com">
              <FilterCom
                v-model="form"
                :active-index="activeIndex"
                :group-item-icon="groupItemIcon"
                :report-detail-list="reportDetailList"
              />
            </el-tab-pane>
            <el-tab-pane label="分组" name="aggregation-com">
              <AggregationCom
                v-if="form.dataSourceQueryInfos.length !== 0"
                v-model="form"
                :active-index="activeIndex"
                :group-item-icon="groupItemIcon"
                :filter-table-list="filterTableList"
                @customSubmit="customSubmit"
              />
              <div v-else style="text-align: center; color: #afafaf; font-size: 14px;">请先选择聚合条件</div>
            </el-tab-pane>
            <el-tab-pane label="扩展" name="custom-com">
              <CustomCom
                v-if="form.dataSourceQueryInfos.length !== 0"
                v-model="form"
                :active-index="activeIndex"
                :group-item-icon="groupItemIcon"
                :filter-table-list="filterTableList"
                @customSubmit="customSubmit"
              />
              <div v-else style="text-align: center; color: #afafaf; font-size: 14px;">请先选择聚合条件</div>
            </el-tab-pane>
          </el-tabs>
        </vue-scroll-bar>
      </div>
      <div class="preview">
        <div class="header">
          <span>
            <el-button
              v-if="form.showType !== 0"
              class="pack-up"
              size="small"
              @click="showEditorControl = !showEditorControl"
            >编辑配置</el-button>
            <el-button
              size="small"
              type="success"
              :loading="submitLoading"
              @click="onSubmit"
            > <svg-icon name="save" />保存 </el-button>
          </span>
          <div style="text-align: center;">
            <span class="add-title" @click="setReportModelName">
              {{ form.modelName }}
              <svg-icon name="edit" />
            </span>
            <p style="font-size: 12px; color: #909399; margin: 0;">
              {{ form.intro }}
            </p>
          </div>
          <div>
            <el-button type="text" icon="el-icon-back" @click="$router.back()">返回上一页</el-button>
          </div>
        </div>
        <div class="chart-preview">
          <div>
            <div class="special-group">
              <el-tooltip
                class="item"
                effect="dark"
                content="隐藏/显示过滤器"
                placement="top"
              >
                <svg-icon class="icon-filter-switch" name="filter" @click="hidden = !hidden" />
              </el-tooltip>
              <span v-if="hidden && form.dataSourceQueryInfos[activeIndex].whereInfos.length > 0" style="font-size: 12px; color: #333;">+{{ form.dataSourceQueryInfos[activeIndex].whereInfos.length }}</span>
              <vue-scroll-bar v-else :ops="{ bar: { size: '2px' }, rail: { size: '2px' } }">
                <template v-for="(dataSourceQueryInfosItem, index) in form.dataSourceQueryInfos">
                  <el-tag
                    v-for="item in dataSourceQueryInfosItem.whereInfos"
                    :key="`${dataSourceQueryInfosItem.tableName + index + item.sqlMemberId + item.colName}`"
                    style="margin-right: 5px;"
                    :type="dataSourceQueryInfosItem.customId === activeDataSourceQueryInfo.customId ? 'success' : 'info'"
                    closable
                    size="mini"
                    @close="handleClose(item)"
                  >
                    {{ item.colDesc }}{{ item.symbolDesc }}{{ item.value }}
                  </el-tag>
                </template>
              </vue-scroll-bar>
            </div>
            <DataChartWrap
              ref="data-chart"
              v-model="form"
              :last-save-form="lastSaveForm"
              :active-index="activeIndex"
              :style="`height: calc(100% - 30px);`"
              @showPlace="onShowPlace"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- 自定义列 -->
    <el-dialog
      title="自定义列"
      :visible.sync="customDialogVisible"
      width="740px"
      @closed="customDialogVisibleClosed"
    >
      <div class="custom-form">
        <el-input v-model="customForm.aliasName" class="custom-alias-name" placeholder="请输入新的列名" /> =
        <el-select v-model="customForm.leftColTitle" value-key="colName" class="operator-item">
          <el-option
            v-for="item in selectInfosList"
            :key="item.colName + item.aliasName"
            :label="item.aliasName"
            :value="item.aliasName"
          />
        </el-select>
        <el-select v-model="customForm.calculateString" class="custom-operator">
          <el-option label="+" value="+" />
          <el-option label="-" value="-" />
          <el-option label="*" value="*" />
          <el-option label="/" value="/" />
        </el-select>
        <el-select v-model="customForm.rightColTitle" class="operator-item">
          <el-option
            v-for="item in selectInfosList"
            :key="item.colName + item.aliasName"
            :label="item.aliasName"
            :value="item.aliasName"
          />
        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="customDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="onSubmitCustomForm">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 占位-->
    <el-dialog title="设置占位筛选项" :visible.sync="showPlaceModel" width="600px">
      <div class="place-model">
        <el-row class="mgn-b20">
          <span class="place-title">所有占位值</span>
          <span>(占位值是报表在业务引用时，可以被用来做二次筛选的指标项)</span>
        </el-row>
        <el-checkbox-group v-model="checkedPlaceList" @change="handleChecked">
          <el-checkbox
            v-for="place in placeList"
            :key="place.colDesc"
            :label="place.colDesc"
            class="checkbox"
          />
        </el-checkbox-group>
        <el-row class="mgn-b20 mgn-t30">
          <span class="place-title">主占位值</span>
          <span>(主占位值是数据一级筛选时输入的筛选项)</span>
        </el-row>
        <el-checkbox-group v-model="checkedTimePlaceList" @change="handleCheckedTime">
          <el-checkbox
            v-for="place in timePlaceList"
            :key="place.colDesc"
            :label="place.colDesc"
            :disabled="place.colDesc === '组织'"
            class="checkbox"
          />
        </el-checkbox-group>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showPlaceModel = false">取 消</el-button>
        <el-button type="primary" @click="onSubmitPlace">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 配置项 -->
    <el-drawer
      :show-close="false"
      :with-header="false"
      :visible.sync="showEditorControl"
      direction="ltr"
      class="editor-config"
    >
      <div v-if="form.showType !== 0 && form.showType !== 9" :key="form.showType" style="height: 100%;">
        <RightControlBar
          v-if="form.chartOptions"
          :show-type="form.showType"
          :js-code-snippet.sync="form.jsCodeSnippet"
          :chart-options.sync="form.chartOptions"
        />
      </div>
    </el-drawer>
  </div>
</template>

<script>
import AggregationCom from './components/aggregation-com'
import FilterCom from './components/filter-com'
import RankCom from './components/rank-com'
import CustomCom from './components/custom-com'
import DataChartWrap from './components/DataChartWrap'
import RightControlBar from './components/right-control-bar'
import SelectInfos from './components/select-infos'
import html2Canvas from 'html2canvas'
import draggable from 'vuedraggable'

import { defaultOrgId } from '@/utils/util'
import { cloneDeep, isEqual } from 'lodash-es'
import editFormTitle from './components/edit-form-title'
import { defaultChartOption } from '@/components/DataChart/utils/config'
import { showTypeText } from '../utils'

export default {
  name: 'CreateOrEdit',
  components: {
    AggregationCom,
    FilterCom,
    RankCom,
    CustomCom,
    DataChartWrap,
    RightControlBar,
    SelectInfos,
    draggable
  },
  data() {
    return {
      type: 'aggregation-com',
      showPlaceModel: false,
      hidden: false,
      submitLoading: false,
      titleForm: {
        modelName: '',
        categoryId: [],
        intro: ''
      },
      form: {
        smartModelId: '',
        modelName: '',
        intro: '',
        calculateColInfos: [],
        dataSourceQueryInfos: [],
        showType: 0,
        queryInfos: [],
        categoryId: '',
        chartOptions: {}
      },
      reportDetailList: [],
      customForm: {
        leftColTitle: '',
        rightColTitle: '',
        aliasName: '',
        calculateString: '+'
      },
      debounceGetData: Function,
      customDialogVisible: false,
      activeDataSourceQueryInfo: {
        selectInfos: [],
        whereInfos: [],
        groupByInfos: [],
        orderByInfos: [] // 排序
      },
      // 表数据
      tableList: [],
      aggregationList: [],
      placeList: [], // 占位列表
      checkedPlaceList: [], // 选择的占位
      checkedTimePlaceList: ['组织'], // 选择的时间占位
      placeholderInfos: [],
      // 上次保存的form参数
      lastSaveForm: {},
      timePlaceList: [{ colDesc: '组织' }], // 勾选的时间占位
      showEditorControl: false,
      env: process.env.NODE_ENV,
      drag: false,
      dragOptions: {
        animation: 200,
        disabled: false
      }
    }
  },
  computed: {
    groupItemIcon() {
      return tag => {
        switch (tag.dataType) {
          case 1:
            return 'int'
          case 2:
            return 'string'
          case 3:
            return 'date'
          default:
            return tag.dataType
        }
      }
    },
    // 当前选中的聚合项配套数据下标
    activeIndex() {
      return this.form.dataSourceQueryInfos.findIndex(item => item.customId === this.activeDataSourceQueryInfo.customId)
    },
    filterTableList() {
      return (tableName = '') => {
        if (this.tableList.length !== 0) {
          const [{ tableDesc = '' } = {}] = this.tableList?.filter(item => {
            return item.tableName === tableName
          })
          return tableDesc || '自定义'
        }
      }
    },
    selectInfosList() {
      return [...this.form.dataSourceQueryInfos.map(item => item.selectInfos).flat(Infinity), ...this.form.calculateColInfos]
    }
  },
  watch: {
    // 当前所选的聚合项
    activeDataSourceQueryInfo(newVal) {
      if (newVal.tableName) {
        this.getReportDetailList(newVal.tableName)
      }
    }
  },
  mounted() {
    const { modelId } = this.$route.query
    this.form.smartModelId = modelId
    if (!modelId) {
      this.form.queryInfos = defaultOrgId()
      this.form.dataSourceQueryInfos[0]?.whereInfos.push({
        colDesc: '任务创建时间',
        colName: 'created_at',
        dataType: 3,
        sqlMemberId: '9a6dc8f0d6b4f4b75841749bd5731aa4',
        symbolDesc: '大于等于',
        value: this.$dayjs().subtract(1, 'month').format('YYYY-MM-DD 00:00:00')
      })
    } else {
      // 获取报表详情
      this.getDetails()
    }
    this.getTableList()
    this.getAggregation()
  },
  methods: {
    // 获取详情
    async getDetails() {
      try {
        const res = await this.$axios.get(this.$API.getModelDetail, { params: { smartModelId: this.form.smartModelId } })
        const params = JSON.parse(res?.sqlParam)
        if (params.chartOptions) {
          params.chartOptions = JSON.parse(params.chartOptions)
        }

        // 兼容单表时的旧数据
        let flag = params.dataSourceQueryInfos?.some(item => item.selectInfos.length !== 1)
        if (flag) {
          params.dataSourceQueryInfos = params.dataSourceQueryInfos
            .map(dataSourceQueryInfosItem => {
              return dataSourceQueryInfosItem.selectInfos.map(selectInfosItem => {
                dataSourceQueryInfosItem.selectInfos = [selectInfosItem]
                return cloneDeep(dataSourceQueryInfosItem)
              })
            })
            .flat(Infinity)
        }

        params.dataSourceQueryInfos = params.dataSourceQueryInfos.map(item => {
          item.customId = this.$nanoid()
          return item
        })
        this.form = cloneDeep(params)
        this.lastSaveForm = cloneDeep(params)

        this.form.smartModelId = this.$route.query.modelId
        this.titleForm.modelName = this.form.modelName
        this.titleForm.categoryId = this.form.categoryId
        this.titleForm.intro = this.form.intro

        this.activeDataSourceQueryInfo = this.form.dataSourceQueryInfos[0]
      } catch (error) {
        console.log(error)
      }
    },
    // 占位列表
    async getPlaceholderList() {
      try {
        let tableNames = []
        this.form.dataSourceQueryInfos.map(v => {
          tableNames.push(v.tableName)
        })
        let req = tableNames.toString()
        if (!req) {
          this.$message.error('请先设置聚合条件')
          return false
        }
        const res = await this.$axios.get(this.$API.getSameColumnList, { params: { tableNames: req } })
        this.placeList = res
        this.showPlaceModel = true
      } catch (error) {
        console.log(error)
      }
    },
    // 所有指标
    async getReportDetailList(tableName) {
      try {
        this.loading = true
        const res = await this.$axios.get(this.$API.getColumnList, { params: { tableName } })
        this.loading = false
        this.reportDetailList = res
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    },
    // 删除过滤条件
    handleClose(row) {
      this.form.dataSourceQueryInfos = this.form.dataSourceQueryInfos.map(item => {
        if (item.customId === this.activeDataSourceQueryInfo.customId) {
          item.whereInfos = item.whereInfos?.filter(item => {
            return item.sqlMemberId + item.value !== row.sqlMemberId + row.value
          })
        }
        return item
      })
    },
    onShowPlace(allPlace, timePlaceList, placeholderInfos) {
      this.checkedPlaceList = allPlace
      this.timePlaceList = timePlaceList
      this.placeholderInfos = placeholderInfos
      this.timePlaceList = [...new Set([...this.timePlaceList, { colDesc: '组织' }])]
      this.checkedTimePlaceList = ['组织']
      this.timePlaceList.map(v => {
        if (v.isJoinCol) {
          this.checkedTimePlaceList.push(v.colDesc)
        }
      })
      this.getPlaceholderList()
    },
    onSubmitPlace() {
      this.$set(this.form, 'placeholderInfos', this.placeholderInfos)
      this.showPlaceModel = false
    },
    handleChecked() {
      this.placeholderInfos = []
      this.timePlaceList = [{ colDesc: '组织' }]
      this.placeList.map(v => {
        if (this.checkedPlaceList.indexOf(v.colDesc) > -1) {
          this.placeholderInfos.push(v)
          if (v.dataType === 3) {
            this.timePlaceList.push(v)
          }
        }
      })
    },
    handleCheckedTime() {
      this.placeholderInfos.map(v => {
        v.isJoinCol = false
        if (this.checkedTimePlaceList.indexOf(v.colDesc) > -1) {
          v.isJoinCol = true
        }
        return v
      })
    },
    // 生成并保存封面图
    saveCoverImg() {
      return new Promise((resolve, reject) => {
        const currentDom = this.$refs['data-chart'].$refs['data-chart']
        html2Canvas(currentDom.$el)
          .then(canvas => {
            canvas.toBlob(blob => {
              const params = new FormData()
              params.append('image', blob)
              this.$axios
                .post(`${this.$API.imageUpload}?bucketType=104`, params)
                .then(({ large }) => {
                  resolve(large)
                })
                .catch(reject)
            }, 'image/png')
          })
          .catch(reject)
      })
    },
    // 表单名称
    async setReportModelName() {
      try {
        this.titleForm.modelName = this.form.modelName
        this.titleForm.categoryId = this.form.categoryId.split(',').filter(item => item)
        this.titleForm.intro = this.form.intro
        await this.$msgbox({
          title: '编辑',
          message: this.$createElement(editFormTitle, {
            attrs: {
              value: this.titleForm
            }
          }),
          showCancelButton: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              if (!this.titleForm.modelName) {
                return this.$message.error('请输入报表名称')
              }
              this.titleForm.categoryId = this.titleForm.categoryId.join(',')
              done()
            } else {
              done()
            }
          }
        })
        this.form = {
          ...this.form,
          ...this.titleForm
        }
        return true
      } catch (error) {
        return false
      }
    },
    // 保存报表
    async onSubmit() {
      if (!this.form.modelName) {
        const flag = await this.setReportModelName()
        if (!flag) return
      }
      this.submitLoading = true
      const params = Object.assign({}, this.form)
      let flag = isEqual(params.chartOptions, defaultChartOption[showTypeText()(params.showType).value])
      if (flag) {
        params.chartOptions = {}
      }
      this.saveCoverImg()
        .then(imgStr => {
          params.coverUrl = imgStr
        })
        .finally(async() => {
          try {
            params.showType = this.$refs['data-chart'].actChart.showType
            params.chartOptions = JSON.stringify(params.chartOptions)
            const res = await this.$axios.post(this.$API.addModel, params)
            this.submitLoading = false
            if (res) {
              this.$message.success('保存成功')
              this.form.smartModelId = res
              if (!this.$route.query.modelName) {
                await this.$router.replace({
                  name: 'StatementCreateOrEdit',
                  query: {
                    modelId: this.form.smartModelId
                  }
                })
                this.getDetails()
              }
              this.$eventBus.$emit('statement-list')
            }
          } catch (error) {
            this.submitLoading = false
            this.$message.error(error.message)
            console.log(error)
          }
        })
    },

    isObjectValueEqualNew(a, b) {
      let aProps = Object.getOwnPropertyNames(a)
      let bProps = Object.getOwnPropertyNames(b)
      if (aProps.length != bProps.length) {
        return false
      }
      for (let i = 0; i < aProps.length; i++) {
        let propName = aProps[i]

        let propA = a[propName]
        let propB = b[propName]
        if (typeof propA === 'object') {
          if (this.isObjectValueEqualNew(propA, propB)) {
            return true
          } else {
            return false
          }
        } else if (propA !== propB) {
          return false
        } else {
          //
        }
      }
      return true
    },

    customSubmit(e) {
      const flag = this.form.calculateColInfos?.some(item => {
        const deep1 = cloneDeep(item)
        const deep2 = cloneDeep(e)
        delete deep1.aliasName
        delete deep2.aliasName
        if (JSON.stringify(deep1) === JSON.stringify(deep2) && JSON.stringify(item) === JSON.stringify(e)) {
          return true
        }
        return false
      })
      if (flag) {
        // this.$message.error('自定义列已存在')
        return false
      } else {
        if (Object.prototype.toString.call(this.form.calculateColInfos) === '[object Array]') {
          e.calculateOrderIndex = this.form.calculateColInfos.length + 1
          this.form.calculateColInfos.push(e)
        } else {
          e.calculateOrderIndex = 1
          this.form.calculateColInfos = [e]
        }
      }
    },

    // 添加selectInfo
    onAddSelectInfos(res, close) {
      res.customId = this.$nanoid()
      if (this.form.dataSourceQueryInfos.length !== 0) {
        res.groupByInfos = this.form.dataSourceQueryInfos[0].groupByInfos
      }
      this.form.dataSourceQueryInfos.push(res)
      if (this.form.dataSourceQueryInfos.length === 1) {
        this.activeDataSourceQueryInfo = this.form.dataSourceQueryInfos[0]
      }
      close()
    },
    // 删除聚合项
    tagDel(dataSourceQueryInfo, { aliasName = '', sqlMemberId }) {
      this.form.dataSourceQueryInfos = this.form.dataSourceQueryInfos.map(item => {
        if (JSON.stringify(dataSourceQueryInfo) === JSON.stringify(item)) {
          if (item.selectInfos.length === 1) {
            item.groupByInfos = []
          }
          item.selectInfos = item.selectInfos.filter(item => item.aliasName + item.sqlMemberId !== aliasName + sqlMemberId)
        }
        return item
      })
      this.form.dataSourceQueryInfos = this.form.dataSourceQueryInfos.filter(item => item.selectInfos.length !== 0)
    },
    // 删除自定义聚合项
    calculateColInfosTagDel(row) {
      this.form.calculateColInfos = this.form.calculateColInfos.filter(item => JSON.stringify(row) !== JSON.stringify(item))
    },
    // 保存自定义列
    onSubmitCustomForm() {
      const { aliasName, leftColTitle, rightColTitle } = this.customForm
      if (!aliasName) {
        this.$message.error('自定义列名不能为空')
        return false
      }
      if (!leftColTitle || !rightColTitle) {
        this.$message.error('请选择要计算的列')
        return false
      }
      const params = Object.assign({}, this.customForm)
      this.customSubmit(params)

      this.customDialogVisible = false
      this.customForm = this.$options.data().customForm
    },
    // 自定义列修改别名
    onCalculateColInfosEdit(row) {
      this.customForm = row
      this.customDialogVisible = true
    },
    // 自定义聚合项弹窗关闭回调
    customDialogVisibleClosed() {
      this.customForm = this.$options.data().customForm
    },
    // 编辑别名
    onEdit(dataSourceQueryInfo, row, attributeName) {
      this.activeDataSourceQueryInfo = dataSourceQueryInfo
      if (!attributeName) return

      this.form.dataSourceQueryInfos = this.form.dataSourceQueryInfos.map(item => {
        if (dataSourceQueryInfo.tableName === item.tableName && item.customId === dataSourceQueryInfo.customId) {
          item.selectInfos.map(selectInfosItem => {
            if (JSON.stringify(selectInfosItem) === JSON.stringify(row)) {
              selectInfosItem[attributeName] = !selectInfosItem[attributeName]
            } else {
              selectInfosItem[attributeName] = false
            }
            return selectInfosItem
          })
        } else {
          item.selectInfos.map(selectInfosItem => {
            selectInfosItem[attributeName] = false
            return selectInfosItem
          })
        }
        return item
      })
      if (this.$refs.saveTagInput?.length) {
        this.$refs['data-chart'].debounceGetData()
      }
      this.$nextTick(() => {
        this.$refs.saveTagInput && this.$refs.saveTagInput[0]?.focus()
      })
    },
    // 聚合条件
    async getAggregation() {
      try {
        const res = await this.$axios.get(this.$API.getRequestSqlMemberList, { params: { type: 2 } })
        this.aggregationList = res
      } catch (error) {
        console.log(error)
      }
    },
    // 获取表数据
    async getTableList() {
      try {
        const res = await this.$axios.get(this.$API.getTableList)
        this.tableList = res
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.create-wrap {
  .tooltip {
    color: #aaa;
    font-size: 12px;
  }
  .header {
    height: 45px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;
    .item {
      margin-right: 15px;
    }
    .add-title {
      .button-new-tag {
        margin-left: 10px;
        height: 32px;
        line-height: 30px;
        padding-top: 0;
        padding-bottom: 0;
        font-size: 12px;
      }
    }
  }
  .create-chart {
    display: flex;
    height: calc(100vh - 85px);
    .controller {
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      border-right: 1px solid #ccc;
      transition: all 0.3s;
      > div:nth-child(1) {
        padding-right: 10px;
      }
      .header {
        display: flex;
        justify-content: space-between;
      }
      .el-scroll {
        flex: 1;
      }
      .report-title {
        border-bottom: 1px solid #ccc;
        padding-bottom: 10px;
        margin-top: 0;
      }
      :deep(.tag-item) {
        width: 100%;
        display: flex;
        margin-bottom: 4px;
        justify-content: space-between;
        align-items: center;
        border: none;
        .select-label {
          margin: 0 auto;
          display: flex;
          width: 100%;
          justify-content: space-between;
          > span:nth-child(1) {
            width: 0;
            flex: 1;
            display: flex;
            align-items: center;
            >span:nth-child(2) {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              margin-left: 5px;
              width: 100%;
              margin-right: 10px;
            }
          }
          >span:nth-child(2) {
            width: auto;
          }
          .input-new-tag {
            width: 100%;
            margin-right: 10px;
          }
          .handle {
            font-size: 18px;
            margin-right: 10px;
            vertical-align: -4px;
            cursor: move;
          }
        }
      }
      .table-scroll {
        width: 290px !important;
        box-sizing: border-box;
        .btn-group {
          display: flex;
          margin-top: 10px;
        }
      }
      :deep(.tabs) {
        .el-tabs__content {
          overflow: inherit;
        }
      }
      .flip-list-move {
        transition: transform 0.5s;
      }
    }
    .preview {
      flex: 1;
      box-sizing: border-box;
      padding-left: 10px;
      padding-top: 5px;
      border-radius: 3px;
      display: flex;
      flex-direction: column;
      .icon-filter-switch {
        width: 20px;
        height: 20px;
        border-radius: 2px;
        background: #67c23a;
        color: #fff;
        padding: 4px;
        transition: all 0.3s;
        &:hover {
          background-color: rgba(122, 199, 94, 0.8);
        }
      }
      .special-group {
        height: 35px;
        display: grid;
        grid-template-columns: 25px 1fr auto;
        align-items: center;
        justify-content: space-between;
        .__vuescroll {
          flex: 1;
          flex-shrink: 0;
          :deep(.__view) {
            display: flex;
            align-items: center;
          }
        }
      }
      .chart-preview {
        display: flex;
        flex: 1;
        height: calc(100% - 45px);
        > div:nth-child(1) {
          width: 100%;
          flex: 1;
          overflow: hidden;
        }
      }
    }
  }
}
.classify {
  :deep(.el-table__row) {
    .el-table__cell {
      padding-bottom: 0;
      padding-top: 14px;
      .el-form-item {
        margin-bottom: 14px;
      }
    }
    .el-table__cell:nth-last-child(1) {
      padding: 12px 0;
    }
  }
}
.custom-form {
  display: flex;
  align-items: center;
  .custom-alias-name {
    width: 160px;
    margin-right: 10px;
  }
  .operator-item {
    margin: 0 10px;
  }
  .custom-operator {
    width: 60px;
  }
}
.checkbox {
  min-width: 150px;
}
.place-model {
  height: 400px;
  overflow: scroll;
}
.place-title {
  font-size: 16px;
  font-weight: bold;
  margin-right: 10px;
}
.editor-config {
  :deep(.el-drawer) {
    width: 320px !important;
  }
}
@media screen and (max-width: 600px) {
  .create-chart {
    display: block;
    .controller,
    .preview {
      width: 100%;
    }
    .preview {
      height: 500px;
    }
  }
}
</style>
