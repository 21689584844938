<template>
  <div class="custom-com">
    <el-form>
      <el-form-item>
        <div class="number-limit">
          数据限制<el-input v-model.number="form.dataSourceQueryInfos[activeIndex].limitCount" class="input" />条
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'CustomCom',
  components: {

  },
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    activeIndex: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      test: ''
    }
  },
  computed: {
    form: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  mounted() {

  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.custom-com {
  .number-limit {
    display: flex;
    align-items: center;
  }
  :deep(.input) {
    width: 100px;
    .el-input__inner {
      border: none;
      border-bottom: 1px solid #ccc;
      border-radius: 0;
    }
  }
}
</style>
