<template>
  <div class="filter-com">
    <div v-show="!activeReport.colName" style="background: #fff;">
      <el-input v-model="filterName" placeholder="输入筛选条件" />
      <div v-for="tag in filterReportDetailList" :key="tag.tableColUnitId + tag.aggregateSqlMemberId + tag.colDesc" class="group-list">
        <svg-icon :name="groupItemIcon(tag)" class="group-icon" />
        <el-tag
          class="check-list-item"
          :effect="tagAct(tag) ? 'dark' : 'plain'"
          :type="tagAct(tag) ? 'success' : 'info'"
          @click="onActiveReport(tag)"
        >
          {{ tag.colDesc }}
        </el-tag>
      </div>
    </div>
    <conditions
      v-if="activeReport.colName"
      v-model="activeReport"
      :form="form"
      :active-index="activeIndex"
      class="conditions"
      :type="groupItemIcon(activeReport)"
      @onSaveFilter="onSaveFilter"
    />
  </div>
</template>

<script>
import conditions from './conditions.vue'

import { getColumnValues } from '@/apis/reportService.js'
export default {
  name: 'FilterCom',
  components: { conditions },
  props: {
    value: {
      type: Object,
      default: Object
    },
    activeIndex: {
      type: Number,
      default: Number
    },
    reportDetailList: {
      type: Array,
      default: Array
    },
    groupItemIcon: Function
  },
  data() {
    return {
      activeReport: {},
      filterName: ''
    }
  },
  computed: {
    form: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    tagAct() {
      return tag => {
        return this.form.dataSourceQueryInfos[this.activeIndex]?.whereInfos?.some(item => {
          return item.colName + item.colDesc === tag.colName + tag.colDesc
        })
      }
    },
    filterReportDetailList() {
      const arr = [...(this.form.dataSourceQueryInfos[this.activeIndex]?.selectInfos || [])?.map(item => {
        return {
          // ...item,
          isPublic: 1,
          colName: item.colName,
          aggregateSqlMemberId: item.sqlMemberId,
          dataType: 1,
          colDesc: item.aliasName || ''
        }
      }), ...this.reportDetailList] || []
      return arr.filter(item => item.colDesc.indexOf(this.filterName) !== -1 && item.isPublic === 1)
    }
  },
  mounted() {
    this.bannerHeight = this.$refs.bannerImg && this.$refs.bannerImg.length ? this.$refs.bannerImg[0].height : '' // 关键的一步
  },
  methods: {
    onSaveFilter(row) {
      this.form.dataSourceQueryInfos[this.activeIndex].whereInfos = [...new Set([row, ...(this.form.dataSourceQueryInfos[this.activeIndex]?.whereInfos || [])].map(item => JSON.stringify(item)))].map(item => JSON.parse(item))
    },
    async getColumnDesc() {
      try {
        const params = {}
        const res = await getColumnValues(params)
        console.log(res)
      } catch (error) {
        console.log(error)
      }
    },
    onActiveReport(tag) {
      this.activeReport = tag
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.filter-com {
  .group-list {
    display: flex;
    align-items: center;
    .group-icon {
      font-size: 18px;
      margin-right: 5px;
    }
  }
  .check-list-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 4px;
    padding-right: 10px;
    border-radius: 2px;
    transition: all 0.2s;
    margin: 2px 0;
    font-size: 14px;
    line-height: 35px;
    border: 1px solid transparent;
    span {
      cursor: pointer;
    }
    &:hover {
      border: 1px solid #ccc;
      cursor: pointer;
    }
  }
}
</style>
